import { PublishedCommentSelection } from 'api-client/publishedCommentContract.js';
import { match, P } from 'ts-pattern';
import { Comment } from 'api-client/types.js';
import {
  concatOrd,
  Ord,
  Ordering,
  ordNumberDesc,
} from './selectionComments/Ord.js';
import { Block } from 'editor-content/Block.js';
import { sortSelectionCommentByContentPosition } from './selectionComments/selectionCommentsSort.js';

export const commentsSort = (section: {
  body: Block[];
}): Ord<{
  createdAt: number;
  selection: PublishedCommentSelection | null;
}> => {
  return concatOrd(
    sectionCommentBeforeSelectionComment(section),
    sortByMostRecentCreated,
  );
};

const sortByMostRecentCreated: Ord<{ createdAt: number }> = (
  comment1,
  comment2,
) => {
  return ordNumberDesc(comment1.createdAt, comment2.createdAt);
};

const sectionCommentBeforeSelectionComment =
  (section: {
    body: Block[];
  }): Ord<{
    selection: PublishedCommentSelection | null;
  }> =>
  (comment1, comment2) => {
    return match([comment1, comment2])
      .returnType<Ordering>()
      .with([{ selection: null }, { selection: P.not(null) }], () => -1)
      .with([{ selection: P.not(null) }, { selection: null }], () => 1)
      .with(
        [{ selection: P.not(null) }, { selection: P.not(null) }],
        ([comment1, comment2]) =>
          sortSelectionCommentByContentPosition(section)(comment1, comment2),
      )
      .otherwise(() => 0);
  };

export type SortableComment = Pick<Comment, 'selection' | 'createdAt'>;
