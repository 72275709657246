import styles from './TableCell.module.scss';
import {
  TableBlockCellFormat,
  TableBorderType,
} from 'editor-content/TableBlock.js';
import compact from 'lodash/compact.js';
import React, { MouseEventHandler } from 'react';
import cx from 'classnames';

const getCellWrapClassName = (
  format: TableBlockCellFormat,
): string | undefined => {
  switch (format.wrap) {
    case 'wrap':
      return styles.cell__wrap;
    case 'clip':
      return styles.cell__clip;
  }
};

const getClassNameFromBorder = (
  border: TableBorderType,
  position: 'top' | 'right' | 'bottom' | 'left',
) => {
  if (!border) {
    return undefined;
  }
  return styles[`cell__border__${position}__${border}`];
};

const horizontalAlignClassNames = {
  left: styles.cell__alignHorizontal__left,
  center: styles.cell__alignHorizontal__center,
  right: styles.cell__alignHorizontal__right,
  none: undefined,
} as const;

const alignVerticalClassNames = {
  top: styles.cell__alignVertical__top,
  middle: styles.cell__alignVertical__middle,
  bottom: styles.cell__alignVertical__bottom,
  none: undefined,
} as const;

const getCellBorderClassNames = (
  format: TableBlockCellFormat,
): Array<string> => {
  return compact([
    getClassNameFromBorder(format.border?.top, 'top'),
    getClassNameFromBorder(format.border?.bottom, 'bottom'),
    getClassNameFromBorder(format.border?.right, 'right'),
    getClassNameFromBorder(format.border?.left, 'left'),
  ]);
};

export const TableCell = React.forwardRef<
  HTMLTableCellElement,
  {
    children: React.ReactNode;
    format?: TableBlockCellFormat;
    width?: number;
    isSelected?: boolean;
    isHighlighted?: boolean;
    isCommented?: boolean;
    selectionEnabled?: boolean;
    onClick?: MouseEventHandler<HTMLElement>;
  } & React.ComponentProps<'td'>
>(function TableCell(
  {
    children,
    format,
    width,
    isSelected,
    isHighlighted,
    isCommented,
    selectionEnabled,
    onClick,
    ...otherProps
  },
  forwardedRef,
) {
  return (
    <td
      ref={forwardedRef}
      {...otherProps}
      style={{
        ...(width
          ? {
              maxWidth: `${width + 28}px`,
              minWidth: `${width - 28}px`,
            }
          : {}),
        ...(format?.bgColor ? { backgroundColor: format.bgColor } : {}),
      }}
      className={cx(
        styles.cell,
        horizontalAlignClassNames[format?.alignHorizontal ?? 'none'],
        alignVerticalClassNames[format?.alignVertical ?? 'none'],
        format && getCellBorderClassNames(format),
        selectionEnabled && styles.cell__selectionEnabled,
        selectionEnabled && isSelected && styles.cell__selected,
        isHighlighted && styles.cell__highlighted,
        isCommented && styles.cell__commented,
      )}
      onClick={onClick}
    >
      <div className={cx(format && getCellWrapClassName(format))}>
        {children}
      </div>
    </td>
  );
});
