import { TextBlock } from 'editor-content/Block.ts';
import { removeHighlights } from './removeHighlights.js';
import { TextNode } from 'editor-content/TextNode.ts';
import addHighlightToTextNodes from '../../../editor/BodyEditor/addHighlightToTextNodes.js';
import { PublishedCommentHighlight } from '../commentsSidebar/useComments/selectionComments/SelectionComments.js';
import { match, P } from 'ts-pattern';

const applyBlockHighlights = <B extends TextBlock>(
  block: B,
  updatedHighlights: PublishedCommentHighlight[],
): TextNode[] => {
  let updatedContent: TextNode[] = removeHighlights(block.content);

  if (!updatedHighlights.length) {
    return updatedContent;
  }

  updatedHighlights.forEach((highlight) => {
    match(highlight)
      .with({ type: 'disowned' }, () => {})
      .with(
        {
          type: P.union('edited', 'valid'),
          selection: { anchorOffset: P.number, focusOffset: P.number },
        },
        (highlight) => {
          updatedContent = addHighlightToTextNodes(
            updatedContent,
            highlight.selection,
            highlight.commentId,
          );
        },
      );
  });

  return updatedContent;
};

export default applyBlockHighlights;
