import cx from 'classnames';
import { ImageBlock } from 'editor-content/Block.js';
import { Icon } from 'icons';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import useApi from '../../api/useApi.ts';
import mergeRefs from '../../junkDrawer/mergeRefs.ts';
import Image from '../atoms/Image.tsx';
import ImageCaption from '../atoms/ImageCaption.tsx';
import ImageBlockLayout from '../atoms/ImageLayout.tsx';
import ImageModal from '../organisms/ImageModal.tsx';
import styles from './Image.module.scss';
import PublishedSelectionCommentForm from '../../pages/zeck/previewAndPublish/publish/selectionComments/PublishedSelectionCommentForm.tsx';
import Tooltip from '../organisms/Tooltip.tsx';
import { SelectionCommentsFeatureAtom } from '../../pages/zeck/previewAndPublish/publish/commentsSidebar/useComments/selectionComments/SelectionComments.js';
import { useAtomValue, useSetAtom } from 'jotai';
import { DisownedHighlight } from '../../pages/zeck/highlights/DisownedHighlight.js';

type ImageViewProps = {
  block: ImageBlock;
  className?: string;
  scrollContainerRef?: RefObject<HTMLElement>;
  selectionCommentsFeatureAtom?: SelectionCommentsFeatureAtom;
  preview?: boolean;
};

type ImageCommentsProps = {
  block: ImageBlock;
  className?: string;
  scrollContainerRef?: RefObject<HTMLElement>;
  selectionCommentsFeatureAtom: SelectionCommentsFeatureAtom;
  commenting: boolean;
  setCommenting: (commenting: boolean) => void;
};

const ImageComments = ({
  selectionCommentsFeatureAtom,
  commenting,
  setCommenting,
  block,
}: ImageCommentsProps) => {
  const addComment = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();

      setCommenting(true);
    },
    [setCommenting],
  );

  const selectionCommentsFeature = useAtomValue(selectionCommentsFeatureAtom);
  const selectFirstCommentForBlock = useSetAtom(
    selectionCommentsFeature.selectFirstCommentForBlockAtom,
  );
  const highlights = useAtomValue(
    selectionCommentsFeature.getBlockHighlights(block.id),
  );
  const commentCount = highlights.length;
  const viewers = useAtomValue(selectionCommentsFeature.viewersAtom);
  const currentUserName = useAtomValue(
    selectionCommentsFeature.currentUserNameAtom,
  );
  const postComment = useSetAtom(selectionCommentsFeature.postCommentAtom);

  const addCommentOrOpenSidebar = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (commentCount > 0) {
        selectFirstCommentForBlock(block.id);
      } else {
        addComment(e);
      }
    },
    [addComment, block.id, commentCount, selectFirstCommentForBlock],
  );

  const imageIconClass = cx([
    styles.image_icon,
    !commentCount && styles.image_icon__empty,
  ]);

  const tooltipText = commentCount
    ? `${commentCount} Comment${commentCount > 1 ? 's' : ''}`
    : 'Comment';

  return (
    <>
      <DisownedHighlight
        highlightIds={highlights.map((highlight) => highlight.commentId)}
      />
      {commenting && (
        <PublishedSelectionCommentForm
          className={styles.image_publishedCommentForm}
          onClose={() => setCommenting(false)}
          viewers={viewers}
          currentUserName={currentUserName}
          onSubmit={async (commentInfo) => {
            await postComment(
              commentInfo.content,
              commentInfo.isDirectMessage,
              { block, snippetSelection: null },
            );
            setCommenting(false);
          }}
        />
      )}
      {!commenting && (
        <div className={imageIconClass} onClick={addCommentOrOpenSidebar}>
          {!!commentCount && (
            <Tooltip tipText="Add Comment" active marginOverride={16}>
              <div data-testid="image-comment-add-another" onClick={addComment}>
                <Icon name="plusComment" />
              </div>
            </Tooltip>
          )}
          <Tooltip tipText={tooltipText} active={true} marginOverride={16}>
            <div
              data-testid="image-comment-counter"
              className={commentCount ? styles.comment_count : undefined}
            >
              <Icon name="commentFilled" />
              {!!commentCount && (
                <div className={styles.image_icon_count}>{commentCount}</div>
              )}
            </div>
          </Tooltip>
        </div>
      )}
    </>
  );
};

const ImageView = React.forwardRef<HTMLDivElement, ImageViewProps>(
  function ImageView(
    {
      block,
      selectionCommentsFeatureAtom,
      scrollContainerRef,
      className,
      preview,
      ...otherProps
    },
    forwardedRef,
  ) {
    const { guid, caption, dimensions, width, align } = block;
    const [fullScreen, setFullScreen] = useState(false);
    const imageRef = useRef<HTMLElement>(null);
    const [visible, setVisible] = useState(false);

    const [commenting, setCommenting] = useState(false);

    useEffect(() => {
      if (!scrollContainerRef?.current || !imageRef.current) {
        return;
      }

      const options = {
        root: scrollContainerRef.current,
        rootMargin: '1000px 0px 1000px 0px',
        threshold: 0,
      };

      const observer = new IntersectionObserver((entries) => {
        if (entries[0]?.isIntersecting) {
          setVisible(true);
        }
      }, options);
      observer.observe(imageRef.current);

      return () => {
        observer.disconnect();
      };
    }, [scrollContainerRef]);

    const { getImage } = useApi();
    const [src, setSrc] = useState<string>('');
    useEffect(() => {
      if (visible || !scrollContainerRef) {
        getImage(guid).then(setSrc);
      }
    }, [guid, setSrc, getImage, visible, scrollContainerRef]);

    return (
      <>
        <ImageBlockLayout
          // Id is used for linking from pdf
          id={block.id}
          {...otherProps}
          {...{
            width,
            className,
            align,
            image: (
              <div
                onClick={() => {
                  if (!commenting) {
                    setFullScreen(!commenting);
                  }
                }}
                className={styles.image_fullScreenEnabled}
              >
                {!preview && selectionCommentsFeatureAtom && (
                  <ImageComments
                    block={block}
                    selectionCommentsFeatureAtom={selectionCommentsFeatureAtom}
                    commenting={commenting}
                    setCommenting={setCommenting}
                  />
                )}
                <Image
                  ref={mergeRefs([forwardedRef, imageRef])}
                  src={src}
                  dimensions={dimensions}
                />
              </div>
            ),
            caption: <ImageCaption>{caption}</ImageCaption>,
          }}
        />

        <ImageModal
          src={src}
          imageBlock={block}
          isOpen={fullScreen}
          onRequestClose={() => {
            setFullScreen(false);
          }}
        />
      </>
    );
  },
);

export default ImageView;
