import { createParagraphBlock } from 'editor-content/Block.js';
import { Text } from 'editor-content/TextNode.js';
import Linkable from 'editor-content/html/Linkable.ts';
import { useRef, useState } from 'react';
import { EditorContent } from './pages/zeck/edit/useEditorState.tsx';
import ZeckEditable from './pages/zeck/editor/ZeckEditable.tsx';
import ZeckView from './pages/zeck/previewAndPublish/ZeckView.tsx';
import { generateZeckVoteCapabilities } from './pages/zeck/voting/VoteCapability.ts';
import { HydratedBlock } from './types/HydratedBlock.ts';
import { Section } from './types.js';
import { TestProvider } from './testHelpers/TestProvider.tsx';

const createInitialContent = (): HydratedBlock[] => [
  createParagraphBlock([
    Text(
      'This is the body. Here is where you begin to tell the story of your update. You can use common formatting here like ',
    ),
    Text('bold', { bold: true }),
    Text(', '),
    Text('italic', { italic: true }),
    Text(', and '),
    Text('underlined', { underline: true }),
    Text(' text.'),
  ]),
];

const EditorTestPage = () => {
  const [editorContent, setEditorContent] = useState<EditorContent>({
    title: 'CEO Summary',
    headline: [
      Text(
        'This is called the Headline. What you write here should be the ‘one thing’ someone should come away with if they read this section. The Headline is limited to 300 characters, so keep it simple and to the point.',
      ),
    ],
    body: createInitialContent(),
  });
  const [mode, setMode] = useState<'edit' | 'preview'>('edit');
  const scrollContainerRef = useRef(document.querySelector('html'));

  Object.assign(window, {
    setTitle(newTitle: string) {
      setEditorContent({
        ...editorContent,
        title: newTitle,
      });
    },
    setBlocks(newBlocks: HydratedBlock[]) {
      setEditorContent({
        ...editorContent,
        body: newBlocks,
      });
    },
  });

  const fakeSection: Section = {
    id: 'fake-as-section',
    title: editorContent.title,
    headline: editorContent.headline,
    body: editorContent.body,
    zeckId: 'fake-as-zeck',
    coverImageId: null,
    coverImageEnabled: false,
    position: 0,
    createdAt: 0,
    supplemental: false,
    version: '123',
    updatedBy: null,
    votes: [],
  };

  const agendaSectionItems = [
    {
      id: 'fake-id1',
      title: 'The Thing You Need To Know',
    },
  ];

  const linkables: Linkable[] = [
    {
      type: 'section',
      itemId: 'fake-id1',
      title: 'The Thing You Need To Know',
      url: 'www.example.com',
    },
    {
      type: 'meeting-minutes',
      itemId: 'fake-uuid2',
      title: 'Meet The Minutes',
      url: 'www.example.com',
    },
  ];

  const company = {
    id: 'fake-id2',
    name: 'Doop Man Inc.',
    brandColorPrimary: '#000000',
    brandLogoId: null,
    role: 'admin' as const,
    boardDirector: true,
    permissions: {
      canManageBrand: true,
      canManageMinutes: true,
      canManagePeople: true,
      canManageZecks: true,
    },
  };

  const user = {
    id: 'fonky-town',
    firstName: 'tonky',
    lastName: 'fown',
    email: 'fonky@tonky.town',
  };

  const { zeckFinalizeVoteCapability, zeckPrevoteCapability } =
    generateZeckVoteCapabilities({
      userIsAdmin: true,
      userCanManageMinutes: true,
      userIsBoardDirector: true,
      prevoteData: {
        results: [],
        tallies: [],
        boardDirectorCount: 8,
        currentUserPrevotes: [],
      },

      takePrevote: null,
      removePrevote: null,
      takeVote: null,
    });

  return (
    <TestProvider activeCompany={company} user={user}>
      <button
        type="button"
        onClick={() => {
          setMode('edit');
        }}
      >
        Edit
      </button>
      <button
        type="button"
        onClick={() => {
          setMode('preview');
        }}
      >
        Preview
      </button>
      {mode === 'edit' && (
        <ZeckEditable
          {...{
            user: {
              id: 'fonky-town',
              firstName: 'tonky',
              lastName: 'fown',
              email: 'fonky@tonky.town',
            },
            addIntegrationItem: () => undefined,
            selectionComments: [],
            integrationData: {
              integrations: [],
              addIntegration: () => undefined,
              updateIntegration: () => undefined,
            },
            zeckId: '',
            zeckName: '',
            sectionId: '',
            editorContent,
            setEditorContent,
            onEditAppearance: () => {
              // does nothing
            },
            linkables: linkables,
            sectionItems: agendaSectionItems,
            company,
            createSelectionComment: () => Promise.resolve(),
            updateSelectionComment: () => Promise.resolve(),
            deleteSelectionComment: () => Promise.resolve(),
            subscribeToEditTitle: () => () => null,
            zeckFinalizeVoteCapability,
            zeckPrevoteCapability,
            prevotedBlockIds: [],
            linkTooltipBehavior: {
              onScroll: () => null,
            },
          }}
        />
      )}
      {mode === 'preview' && (
        <ZeckView
          zeck={{
            name: 'fake-zeck',
            id: '12312',
            coverImageId: null,
            companyId: 'fake-id2',
            createdAt: 0,
            publishedZeck: null,
            publishedAt: null,
            firstPublishedAt: null,
            isAdmin: true,
            isContributor: true,
            isViewer: true,
            sections: [fakeSection],
            disableComments: true,
            settings: {
              zeckId: '123',
              allowRequestAccess: false,
              disablePrintToPdf: false,
              disableComments: false,
            },
            folderId: null,
            deletedAt: null,
            trashedAt: null,
            resources: [],
          }}
          zeckFinalizeVoteCapability={zeckFinalizeVoteCapability}
          zeckPrevoteCapability={zeckPrevoteCapability}
          scrollContainerRef={scrollContainerRef}
          linkables={linkables}
          company={company}
          sectionsWithRef={[
            {
              data: fakeSection,
              getEl: () => undefined,
              setEl: () => null,
            },
          ]}
          onFilePreview={() => {}}
        />
      )}
    </TestProvider>
  );
};
export default EditorTestPage;
