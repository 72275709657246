import { CommentsStateForSection } from '../useComments.js';
import {
  PublishedSelectionCommentsUIState,
  SelectionCommentsFeatureAtom,
} from './SelectionComments.js';
import { Atom, atom } from 'jotai';
import { CommentContent } from 'editor-content/CommentContent.js';
import { Comment, Section } from 'api-client/types.js';
import { createBlockHighlightsAtomFamily } from './createBlockHighlightsAtomFamily.js';
import { PrimitiveAtom } from 'jotai/vanilla/atom';
import { atomFamily } from 'jotai/utils';
import { createSelectionCommentsBlockFeatureAtom } from './createSelectionCommentsBlockFeatureAtom.js';
import { isTextBlock } from 'editor-content/Block.js';
import { isTableBlock } from 'editor-content/TableBlock.js';

export function createSelectionCommentsFeatureAtom(
  commentsStateForSection: CommentsStateForSection,
  commentsAtom: Atom<Comment[]>,
  uiStateAtom: PrimitiveAtom<PublishedSelectionCommentsUIState>,
  section: Pick<Section, 'body'>,
): SelectionCommentsFeatureAtom {
  const postCommentAtom = atom(
    null,
    async (
      _get,
      _set,
      content: CommentContent,
      asDirectMessage: boolean,
      selection: Comment['selection'] | null,
    ) => {
      await commentsStateForSection.postComment(
        content,
        asDirectMessage,
        selection,
      );
    },
  );

  return atom({
    getBlockHighlights: createBlockHighlightsAtomFamily(commentsAtom, section),

    selectCommentAtom: atom(null, (_get, _set, commentId: string) => {
      commentsStateForSection.openComment(commentId);
    }),

    selectedCommentIdAtom: atom(() =>
      commentsStateForSection.selectedComment.getSelectedCommentThreadId(),
    ),

    canAddNewCommentAtom: atom(
      () => commentsStateForSection.newComment.canAddNewComment,
    ),

    selectFirstCommentForBlockAtom: atom(
      null,
      (_get, _set, blockId: string) => {
        const commentsForBlock =
          commentsStateForSection.filteredCommentsWithActions.filter(
            (c) => c.selection?.block.id === blockId,
          );
        const firstCommentBySomeCriteria = commentsForBlock[0];

        if (firstCommentBySomeCriteria) {
          commentsStateForSection.openComment(firstCommentBySomeCriteria.id);
        }
      },
    ),

    postCommentAtom: postCommentAtom,

    viewersAtom: atom(() => commentsStateForSection.viewers),
    currentUserNameAtom: atom(() => commentsStateForSection.userName),

    blockSpecificUIAtomFamily: atomFamily((blockId: string) => {
      const block = section.body.find(
        (sectionBlock) => sectionBlock.id === blockId,
      );
      if (!block) {
        throw new Error(
          'SelectionCommentsFeatureAtom: Could not find block in section',
        );
      }
      if (!(isTextBlock(block) || isTableBlock(block))) {
        throw new Error(
          'SelectionCommentsFeatureAtom: Tried to comment on a unsupported block type',
        );
      }

      return createSelectionCommentsBlockFeatureAtom(block, uiStateAtom);
    }),
  });
}
