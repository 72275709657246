import pick from 'lodash/pick.js';
import {
  ExternalSelectionType,
  MAX_TABLE_COLUMNS,
  MAX_TABLE_ROWS,
  TableBlock,
  TableBlockCell,
  TableBlockCellFormat,
  TableBlockRow,
} from 'editor-content/TableBlock.js';
import { Editor, Text, TextFormat } from 'editor-content/TextNode.js';
import normalizeTableData from '../normalizeTableData.js';
import {
  validateAndLogTableErrors,
  createFrozenDimensions,
} from '../tableUtils.js';

export type SheetExternalInfo = {
  documentName: string;
  documentId: string;
  selectionName: string;
  selectionId: number;
  selectionType: ExternalSelectionType;
};

const hasBorders = (cell: gapi.client.sheets.CellData | undefined): boolean => {
  const borders = cell?.effectiveFormat?.borders;
  if (!borders) return false;

  return Object.values(borders).some(
    (border) => border?.style && border.style !== 'NONE',
  );
};

const hasContent = (cell: gapi.client.sheets.CellData | undefined): boolean =>
  Boolean(cell?.formattedValue) || hasBorders(cell);

const findFirstNonEmptyColumn = (
  rows: gapi.client.sheets.RowData[],
): number => {
  if (rows.length === 0) return 0;

  const maxCols = Math.max(...rows.map((row) => row.values?.length ?? 0));

  return (
    [...Array(maxCols).keys()].find((col) =>
      rows.some((row) => hasContent(row.values?.[col])),
    ) ?? 0
  );
};

const findFirstNonEmptyRow = (rows: gapi.client.sheets.RowData[]): number =>
  rows.findIndex((row) => row.values?.some((cell) => hasContent(cell))) || 0;

const getPopulatedCellLength = (row: gapi.client.sheets.RowData): number => {
  if (!row.values) return 0;

  for (let i = row.values.length - 1; i >= 0; i--) {
    if (hasContent(row.values[i])) {
      return i + 1;
    }
  }
  return 0;
};

const getPopulatedSheetDimensions = (
  rows: gapi.client.sheets.RowData[],
): {
  actualNumRows: number;
  actualNumCols: number;
  startRow: number;
  startColumn: number;
} => {
  const rowLengths = rows.map(getPopulatedCellLength);

  // get maximum populated row length
  const actualNumCols = Math.max(0, ...rowLengths);

  // get index of last populated row
  const actualNumRows = rowLengths.reduce((prevMaxIndex, rowLength, i) => {
    if (rowLength !== 0) {
      return i + 1;
    }
    return prevMaxIndex;
  }, 0);

  return {
    startRow: findFirstNonEmptyRow(rows),
    startColumn: findFirstNonEmptyColumn(rows),
    actualNumRows,
    actualNumCols,
  };
};

function getBorderType(
  border: gapi.client.sheets.Border | undefined,
): 'thin' | 'thick' | undefined {
  if (border?.style === 'SOLID_THICK' || border?.style === 'DOUBLE') {
    return 'thick';
  }

  if (!border?.style || border?.style === 'NONE') {
    return undefined;
  }

  return 'thin';
}

function createCellFormatFromGoogleCell(
  cell: gapi.client.sheets.CellData,
): TableBlockCellFormat | undefined {
  if (!cell.effectiveFormat) return;

  const alignHorizontal = getCellAlignHorizontal(
    cell.effectiveFormat.horizontalAlignment,
  );
  const alignVertical = getCellAlignVertical(
    cell.effectiveFormat.verticalAlignment,
  );
  const wrap = getCellWrap(cell.effectiveFormat.wrapStrategy);

  const border = {
    top: getBorderType(cell.effectiveFormat.borders?.top),
    bottom: getBorderType(cell.effectiveFormat.borders?.bottom),

    left: getBorderType(cell.effectiveFormat.borders?.left),
    right: getBorderType(cell.effectiveFormat.borders?.right),
  };

  return {
    ...alignHorizontal,
    ...alignVertical,
    ...wrap,
    border,
  };
}

function getCellWrap(
  googleWrap: string | undefined,
): Pick<TableBlockCellFormat, 'wrap'> {
  switch (googleWrap) {
    case 'WRAP':
    case 'LEGACY_WRAP':
      return { wrap: 'wrap' };
    case 'CLIP':
    default:
      return { wrap: 'clip' };
  }
}

function getCellAlignHorizontal(
  googleAlignment: string | undefined,
): Pick<TableBlockCellFormat, 'alignHorizontal'> {
  switch (googleAlignment) {
    case 'CENTER':
      return { alignHorizontal: 'center' };
    case 'RIGHT':
      return { alignHorizontal: 'right' };
    case 'LEFT':
    default:
      return { alignHorizontal: 'left' };
  }
}

function getCellAlignVertical(
  googleAlignment: string | undefined,
): Pick<TableBlockCellFormat, 'alignVertical'> {
  switch (googleAlignment) {
    case 'MIDDLE':
      return { alignVertical: 'middle' };
    case 'TOP':
      return { alignVertical: 'top' };
    case 'BOTTOM':
    default:
      return { alignVertical: 'bottom' };
  }
}

const getTextNodeFromGoogleCell = (
  cell: gapi.client.sheets.CellData,
): Editor.Text[] => {
  const format: TextFormat = pick(cell.effectiveFormat?.textFormat, [
    'bold',
    'italic',
    'underline',
  ]);

  return [Text(cell?.formattedValue ?? '', format)];
};

const DEFAULT_COLUMN_WIDTH = 100;
const getColumnWidths = (gridData: gapi.client.sheets.GridData | undefined) => {
  const columnWidths: Record<number, number> = {};

  (gridData?.columnMetadata ?? []).forEach((column, i) => {
    if (column?.pixelSize && column?.pixelSize !== DEFAULT_COLUMN_WIDTH) {
      columnWidths[i] = column.pixelSize;
    }
  });

  return columnWidths;
};

const DEFAULT_ROW_HEIGHT = 21;
const getRowHeights = (gridData: gapi.client.sheets.GridData | undefined) => {
  const rowHeights: Record<number, number> = {};

  (gridData?.rowMetadata ?? []).forEach((row, i) => {
    if (row?.pixelSize && row?.pixelSize !== DEFAULT_ROW_HEIGHT) {
      rowHeights[i] = row.pixelSize;
    }
  });

  return rowHeights;
};

const getFrozenDimensions = (sheet: gapi.client.sheets.Sheet) => {
  return createFrozenDimensions(
    sheet.properties?.gridProperties?.frozenRowCount,
    sheet.properties?.gridProperties?.frozenColumnCount,
  );
};

const adjustDimensionsByOffset = <T>(
  dimensions: Record<number, T>,
  offset: number,
): Record<number, T> =>
  Object.entries(dimensions).reduce(
    (acc, [key, value]) => {
      const adjustedKey = parseInt(key) - offset;
      if (adjustedKey >= 0) {
        acc[adjustedKey] = value;
      }
      return acc;
    },
    {} as Record<number, T>,
  );

export default function createTableFromGoogleSheet(
  sheet: gapi.client.sheets.Sheet,
): Pick<TableBlock, 'data' | 'frozenRows' | 'frozenColumns'> {
  const rawGoogleRows = sheet?.data?.[0]?.rowData;

  if (!rawGoogleRows) {
    throw new Error('unable to translate google sheet data');
  }

  const googleRows = rawGoogleRows.slice(0, MAX_TABLE_ROWS).map((row) => ({
    ...row,
    values: (row?.values ?? []).slice(0, MAX_TABLE_COLUMNS),
  }));

  const { actualNumRows, actualNumCols, startRow, startColumn } =
    getPopulatedSheetDimensions(googleRows);

  // Create rows using map for a more functional approach
  const rows: TableBlockRow[] = Array.from(
    { length: actualNumRows - startRow },
    (_, j) => {
      const rowIndex = j + startRow;
      const googleCells = googleRows[rowIndex]?.values || [];

      const cells = Array.from(
        { length: actualNumCols - startColumn },
        (_, i) => {
          const colIndex = i + startColumn;
          const cell = googleCells[colIndex];

          return cell
            ? TableBlockCell(
                getTextNodeFromGoogleCell(cell),
                createCellFormatFromGoogleCell(cell),
              )
            : TableBlockCell([Text('')]);
        },
      );

      return TableBlockRow(cells);
    },
  );

  // Adjust merges to account for skipped columns and rows
  const adjustedMerges = (sheet.merges || [])
    .filter(
      (
        merge,
      ): merge is Required<Omit<gapi.client.sheets.GridRange, 'sheetId'>> =>
        typeof merge.startColumnIndex !== 'undefined' &&
        typeof merge.endColumnIndex !== 'undefined' &&
        typeof merge.startRowIndex !== 'undefined' &&
        typeof merge.endRowIndex !== 'undefined',
    )
    .map((merge) => {
      const mergeRowOffset = sheet.data?.[0]?.startRow ?? 0;
      const mergeColumnOffset = sheet.data?.[0]?.startColumn ?? 0;

      return {
        startColumn: merge.startColumnIndex - mergeColumnOffset - startColumn,
        startRow: merge.startRowIndex - mergeRowOffset - startRow,
        columnSpan: merge.endColumnIndex - merge.startColumnIndex,
        rowSpan: merge.endRowIndex - merge.startRowIndex,
      };
    })
    .filter((merge) => merge.startColumn >= 0 && merge.startRow >= 0);

  const frozenDimensions = getFrozenDimensions(sheet);
  const gridData = sheet?.data?.[0];

  const newTable = {
    data: normalizeTableData({
      columnWidths: adjustDimensionsByOffset(
        getColumnWidths(gridData),
        startColumn,
      ),
      rowHeights: adjustDimensionsByOffset(getRowHeights(gridData), startRow),
      rows,
      merges: adjustedMerges,
    }),
    ...frozenDimensions,
  };

  return validateAndLogTableErrors(newTable);
}
