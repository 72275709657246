import CartaCapTable from './cartaCapTable/CartaCapTable.tsx';
import { CartaCapTableBlock } from 'editor-content/CartaBlock.ts';
import WideWidthBlockContainer from './WideWidthBlockContainer.js';

type CartaCapTableViewProps = {
  block: CartaCapTableBlock;
  className?: string;
};
function CartaCapTableView({ className, block }: CartaCapTableViewProps) {
  return (
    <WideWidthBlockContainer id={block.id} className={className}>
      <CartaCapTable block={block} />
    </WideWidthBlockContainer>
  );
}

export default CartaCapTableView;
