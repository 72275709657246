import { ChartBlock, createChartBlock } from 'editor-content/ChartBlock.ts';
import { dataUrlToFile } from './dataUrlToFile.ts';
import { TableBlock, tableCellUncompress } from 'editor-content/TableBlock.ts';
import { AiChartFlowProps, AiChartFlowState } from './AiChartFlow.tsx';
import useApi from '../../../../api/useApi.ts';
import { useCallback, useState } from 'react';
import ContentSelection from '../../../../editor/selection/contentSelection/ContentSelection.ts';
import { AiContext } from './createAiContext.ts';
import { ASSET_CHART_ARTIFACT_TYPE } from 'api-client/assetContract.ts';
import { ChartJsSchema } from 'editor-content/lib/chartBlockConversions.ts';
import { useUser } from '../../../../userAndCompany/userAtom.tsx';
import { useActiveCompany } from '../../../../userAndCompany/activeCompanyAtom.tsx';
import { createEventUserPayload } from '../../../../api/endpoints/createAnalyticsApi.ts';
import { ChartBlockData } from 'blocks/src/ChartBlock.js';

type GenerateAiChartFlowProps = {
  onInsertChartBlock: (chart: ChartBlock) => void;
  fromBlock: TableBlock | ChartBlock;
  selection: ContentSelection | null;
  aiContext: AiContext;
  setAddingChart: (value: boolean) => void;
  initialChartState?: AiChartFlowState;
};

export type GenerateAiChartFlowResult = AiChartFlowProps & {
  forceTableSelection: boolean;
  isSourceBlockActive: boolean;
};

export const useGenerateAiChartFlowProps = ({
  onInsertChartBlock,
  fromBlock,
  selection,
  aiContext,
  setAddingChart,
  initialChartState = undefined,
}: GenerateAiChartFlowProps): GenerateAiChartFlowResult => {
  // TODO: does this belong here?
  const { aiChartGenerate, addZeckEvent, createAsset, uploadAsset } = useApi();
  const user = useUser();
  const activeCompany = useActiveCompany();
  const [chartState, setChartState] = useState<AiChartFlowState>(
    initialChartState ?? {
      type: 'prompting',
    },
  );
  const [forceTableSelection, setForceTableSelection] = useState(false);

  const generateDataArray = () => {
    if (fromBlock.type === 'table') {
      return fromBlock.data.rows.map((row) =>
        row.cells.map(
          (cell) => tableCellUncompress(cell).content?.[0]?.text ?? '',
        ),
      );
    }
    return fromBlock.data.sourceData;
  };

  const isBlockSelected = selection !== null;
  const prompting = chartState.type === 'prompting';

  const isSourceBlockActive = prompting ? isBlockSelected : true;

  const onClose = useCallback(() => {
    setAddingChart(false);
    setChartState({
      type: 'prompting', // initial state reset
    });
    setForceTableSelection(false);
  }, [setAddingChart, setChartState, setForceTableSelection]);

  return {
    generateAiChart: async (
      currentMessage: string,
      lastSchema?: ChartJsSchema,
      pastMessages?: string[],
    ) =>
      aiChartGenerate({
        data: generateDataArray(),
        currentMessage,
        companyId: activeCompany.id,
        sectionId: aiContext.sectionId,
        userId: user.id,
        lastSchema,
        pastMessages,
        metadata: createEventUserPayload({
          user,
          activeCompany,
        }),
      }),
    forceTableSelection,
    setForceTableSelection,
    onClose,
    recordAnalytics: async (type: 'accept' | 'discard') => {
      await addZeckEvent(
        {
          contentType: 'SECTION',
          contentTypeId: aiContext.sectionId,
          eventType: type === 'accept' ? 'AI_CHART_ACCEPT' : 'AI_CHART_DISCARD',
          payload: {
            tableBlockId: fromBlock.id,
          },
          firedAt: new Date().getTime(),
        },
        { user, activeCompany },
      );
    },
    onAccept: async (
      chartData: ChartBlockData,
      base64ImageData: string,
      pastMessages: string[],
    ) => {
      const { assetId, writeUrl } = await createAsset({
        companyId: activeCompany.id,
        assetType: ASSET_CHART_ARTIFACT_TYPE,
      });
      const file = await dataUrlToFile(base64ImageData, 'chart.png');
      await uploadAsset(writeUrl, file);

      const chartBlock = createChartBlock(
        assetId,
        chartData,
        generateDataArray(),
        pastMessages,
      );

      onInsertChartBlock(chartBlock);
    },
    chartState,
    setChartState,
    isSourceBlockActive,
  };
};
