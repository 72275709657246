import { Block } from 'editor-content/Block.js';
import { concatOrd, Ord, ordNumberAsc } from './Ord.js';
import { PublishedCommentSelection } from 'api-client/publishedCommentContract.js';
import {
  findCommentSelectionInSection,
  SelectionCommentContentPosition,
} from './findCommentSelectionInSection.js';
import { match, P } from 'ts-pattern';
import { getStartOfSelection } from '../../../../../../../editor/selection/contentSelection/ContentSelection.js';
import { TableSelection } from '../../../../../../../design-system/zeck/table/TableSelection.js';
import { MAX_TABLE_COLUMNS } from 'editor-content/TableBlock.js';

export const sortSelectionCommentByContentPosition =
  (section: {
    body: Block[];
  }): Ord<{
    selection: PublishedCommentSelection;
  }> =>
  (comment1, comment2) => {
    const comment1ContentPosition = findCommentSelectionInSection(
      comment1,
      section,
    );
    const comment2ContentPosition = findCommentSelectionInSection(
      comment2,
      section,
    );

    if (
      comment1ContentPosition.type === 'orphaned' &&
      comment2ContentPosition.type === 'orphaned'
    ) {
      return 0;
    }

    if (comment1ContentPosition.type === 'orphaned') {
      return 1;
    }

    if (comment2ContentPosition.type === 'orphaned') {
      return -1;
    }

    return concatOrd(sortByBlockIndex, sortByPositionWithinBlock)(
      comment1ContentPosition,
      comment2ContentPosition,
    );
  };

type SelectionCommentContentPositionWithBlock = Exclude<
  SelectionCommentContentPosition,
  { type: 'orphaned' }
>;

const sortByBlockIndex = (
  contentPosition1: SelectionCommentContentPositionWithBlock,
  contentPosition2: SelectionCommentContentPositionWithBlock,
) => {
  return ordNumberAsc(contentPosition1.blockIndex, contentPosition2.blockIndex);
};

const sortByPositionWithinBlock = (
  contentPosition1: SelectionCommentContentPositionWithBlock,
  contentPosition2: SelectionCommentContentPositionWithBlock,
) => {
  if (
    contentPosition1.type === 'disowned' &&
    contentPosition2.type === 'disowned'
  ) {
    return 0;
  }

  if (contentPosition1.type === 'disowned') {
    return -1;
  }

  if (contentPosition2.type === 'disowned') {
    return 1;
  }

  return ordNumberAsc(
    match(contentPosition1.selection)
      .with(
        { anchorOffset: P.number, focusOffset: P.number },
        getStartOfSelection,
      )
      .with(
        {
          rowIndex: P.number,
          columnIndex: P.number,
        },
        getTableSelectionOrder,
      )
      .exhaustive(),
    match(contentPosition2.selection)
      .with(
        { anchorOffset: P.number, focusOffset: P.number },
        getStartOfSelection,
      )
      .with(
        {
          rowIndex: P.number,
          columnIndex: P.number,
        },
        getTableSelectionOrder,
      )
      .exhaustive(),
  );
};

const getTableSelectionOrder = (selection: TableSelection): number =>
  selection.rowIndex * MAX_TABLE_COLUMNS + selection.columnIndex;
