import HoverNextToPoint from '../../../../../domHelpers/hoverNextTo/HoverNextToPoint.tsx';
import arrayIsNotEmpty from '../../../../../junkDrawer/arrayIsNotEmpty.ts';
import DetectsOutsideClick from '../../../../../junkDrawer/DetectsOutsideClick.tsx';
import { User } from '../../../../../types.ts';
import { SelectionCommentsBehavior } from '../SelectionCommentsBehavior.ts';
import SelectionCommentsMenu from './SelectionCommentsMenu.tsx';
import useDarkenHighlights from '../../../highlights/useDarkenHighlights.ts';
import { highlightSelector } from '../../../highlights/highlights.js';

type SelectionCommentsExperienceProps = {
  children?: never;
  zeckId: string;
  sectionId: string;
  documentRef: React.RefObject<HTMLElement>;
  user: User;
  selectionCommentsBehavior: SelectionCommentsBehavior;
};

const SelectionCommentsExperience: React.FunctionComponent<
  SelectionCommentsExperienceProps
> = ({ zeckId, sectionId, user, documentRef, selectionCommentsBehavior }) => {
  const highlightIds = selectionCommentsBehavior.currentHighlightIds;

  useDarkenHighlights(highlightIds);

  if (!arrayIsNotEmpty(highlightIds)) return null;

  const highlightId = highlightIds[0];

  return (
    <HoverNextToPoint
      usePortal
      getPoint={(childElement) => {
        const documentEl = documentRef.current;
        if (!documentEl) return [0, 0];

        const el = documentEl.querySelector(highlightSelector(highlightId));

        if (!el) return [0, 0];

        const rect = el.getBoundingClientRect();
        const childRect = childElement.getBoundingClientRect();
        return [
          (rect.left + rect.right) / 2 - childRect.width / 2,
          rect.top + rect.height + 8,
        ];
      }}
    >
      <DetectsOutsideClick onClick={selectionCommentsBehavior.closeCommentMenu}>
        {(ref) => (
          <SelectionCommentsMenu
            autofocus={selectionCommentsBehavior.isAddingNewComment}
            ref={ref}
            user={user}
            key={highlightId}
            comments={selectionCommentsBehavior.selectionComments.filter(
              ({ selectionId }) => highlightId === selectionId,
            )}
            onPostComment={async (content) => {
              if (selectionCommentsBehavior.isAddingNewComment) {
                await selectionCommentsBehavior.addNewComment(content);
              } else {
                await selectionCommentsBehavior.addCommentToThread(
                  highlightId,
                  content,
                );
              }
            }}
            zeckId={zeckId}
            sectionId={sectionId}
          />
        )}
      </DetectsOutsideClick>
    </HoverNextToPoint>
  );
};

export default SelectionCommentsExperience;
