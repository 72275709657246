import { Block, FileBlock } from 'editor-content/Block.ts';
import {
  CompletedVoteBlock,
  isVoteBlock,
  OutstandingVoteBlock,
  VoteBlock,
} from 'editor-content/VoteBlock.ts';
import { VoteType } from 'blocks/src/VoteBlock.ts';
import { v4 } from 'uuid';

export type VoteBlockHydrated = OutstandingVoteBlock | CompletedVoteBlock;

export const outstandingVoteBlock = (
  id: string,
  title: string,
  details: string,
  supportingDocuments: FileBlock[],
  voteType: VoteType,
): OutstandingVoteBlock => ({
  ...VoteBlock(id, title, details, supportingDocuments, voteType),
});

export const completedVoteBlock = (
  id: string,
  title: string,
  details: string,
  supportingDocuments: FileBlock[],
  approved: boolean,
  votedAt: number,
  voteType: VoteType,
): CompletedVoteBlock => ({
  ...VoteBlock(id, title, details, supportingDocuments, voteType),
  approved,
  votedAt,
});

export const createOutstandingVoteBlock = (title: string, details: string) =>
  outstandingVoteBlock(v4(), title, details, [], VoteType.None);

export const createCompletedVoteBlock = (
  title: string,
  details: string,
  approved: boolean,
  voteType: VoteType = VoteType.None,
) =>
  completedVoteBlock(v4(), title, details, [], approved, Date.now(), voteType);

export const isCompletedVoteBlock = (
  block: Block,
): block is CompletedVoteBlock =>
  isVoteBlock(block) && (block as CompletedVoteBlock).approved !== undefined;

export const isOutstandingVoteBlock = (
  block: Block,
): block is OutstandingVoteBlock =>
  isVoteBlock(block) && (block as CompletedVoteBlock).approved === undefined;
