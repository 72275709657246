import { makeMakeApiRequest } from '../makeMakeApiRequest.js';
import { CompanyLite, User, UserAndPermission } from '../../types.ts';

export type Event = {
  userId: string;
  companyId: string;
  contentType: 'ZECK' | 'SECTION' | 'PAGE';
  contentTypeId: string;
  eventType:
    | 'VIEW'
    | 'SCROLL'
    | 'AI_INSERT'
    | 'AI_REJECT'
    | 'AI_RATING'
    | 'AI_CHART_ACCEPT'
    | 'AI_CHART_DISCARD'
    | 'AI_TABLE_KEY_TAKEAWAYS_ACCEPT'
    | 'AI_TABLE_KEY_TAKEAWAYS_DISCARD';
  payload: Record<string, unknown>;
  firedAt: number;
};

export type ZeckUserView = {
  userId: string;
  lastViewedAt: number;
};

export type UserEngagementTotal = {
  userId: string;
  sectionId: string;
  seconds: number;
};

export type HydratedEngagementTotal = {
  sectionId: string;
  seconds: number;
  userId: string;
  user: UserAndPermission;
};

export type EngagementSummary = {
  viewedPercent: number;
  totalMinutesSpent: number;
  averageTimeSpent: number;
};

export type AnalyticsSummary = {
  engagementTotals: HydratedEngagementTotal[];
  engagementSummary: EngagementSummary;
  userTotals: Record<string, number>;
  userCommentTotals: Record<string, number>;
  zeckViews: ZeckUserView[];
  getUserView: (userId: string) => ZeckUserView | undefined;
};

type UserAndCompanySubset = {
  user: Pick<User, 'email' | 'id'>;
  activeCompany: Pick<CompanyLite, 'boardDirector' | 'role' | 'id'>;
};

export const createEventUserPayload = (
  userAndCompany: UserAndCompanySubset,
) => {
  return {
    isZeckEmail: userAndCompany.user.email.endsWith('@zeck.app'),
    companyRole: userAndCompany.activeCompany.role,
    boardDirector: userAndCompany.activeCompany.boardDirector,
  };
};

const createAnalyticsApi = (
  makeApiRequest: ReturnType<typeof makeMakeApiRequest>,
  accessToken: string | null,
) => ({
  addZeckEvent: async (
    event: Omit<Event, 'userId' | 'companyId'>,
    userAndCompany: UserAndCompanySubset,
  ): Promise<void> => {
    const eventWithUserPayload = {
      ...event,
      companyId: userAndCompany.activeCompany.id,
      userId: userAndCompany.user.id,
      payload: {
        ...createEventUserPayload(userAndCompany),
        ...event.payload,
      },
    };

    // uses obfuscated URL due to blockers
    return await makeApiRequest(`/a/e`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(eventWithUserPayload),
    });
  },
  getZeckAnalytics: async (zeckId: string): Promise<ZeckUserView[]> => {
    return await makeApiRequest(`/analytics/zeck-views/${zeckId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
  getZeckEngagementTotals: async (
    zeckId: string,
  ): Promise<UserEngagementTotal[]> => {
    return await makeApiRequest(`/analytics/engagement-totals/${zeckId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
});

export default createAnalyticsApi;
