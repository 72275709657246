import { Block } from 'editor-content/Block.ts';
import { ChartBlockData } from 'blocks/src/ChartBlock.js';
import { makeMakeApiRequest, RequestError } from '../makeMakeApiRequest.js';
import { EditorContent } from '../../pages/zeck/edit/useEditorState.js';
import {
  AiChartGenerateErrorType,
  AiTableKeyTakeawaysDTO,
  isAiTableKeyTakeawaysErrorResponse,
} from 'api-client/aiContract.ts';
import AiApiClient from './AiApiClient.ts';
import { ChartJsSchema } from 'editor-content/lib/chartBlockConversions.ts';
import { z } from 'zod';
import { createAiChartGenerateApiMethod } from './ai/aiChartGenerate.js';
import { getSleepDuration, MAX_RETRIES, sleep } from './ai/aiRequestRetry.js';

export type AiOptimizeSectionSuccessResponse = {
  data?: {
    body?: Block[];
    metadata: {
      jobId: string;
    };
  };
};

export type AiOptimizeSectionErrorResponse = {
  error: string;
};

type AiOptimizeSectionResponse =
  | AiOptimizeSectionSuccessResponse
  | AiOptimizeSectionErrorResponse;

export const isAiOptimizeSectionErrorResponse = (
  response: AiOptimizeSectionResponse,
): response is AiOptimizeSectionErrorResponse =>
  response.hasOwnProperty('error');

export type AiChartGenerateResponseErrorType =
  | AiChartGenerateErrorType
  | 'timeout';

export type AiChartGenerateResponseError = {
  errorType: AiChartGenerateResponseErrorType;
};

export type AiChartGenerateResponse =
  | {
      chart: ChartBlockData;
      lastSchema: ChartJsSchema | undefined;
      pastMessages: string[];
    }
  | AiChartGenerateResponseError;

export const isAiChartGenerateResponseError = (
  response: AiChartGenerateResponse,
): response is AiChartGenerateResponseError => 'errorType' in response;

export type AiTableKeyTakeawaysResponse =
  | {
      tableKeyTakeaways: string[];
    }
  | AiTableKeyTakeawaysResponseError;

export type AiTableKeyTakeawaysResponseError = {
  errorType: 'unknown';
};

export const isAiTableKeyTakeawaysResponseError = (
  response: AiTableKeyTakeawaysResponse,
): response is AiTableKeyTakeawaysResponseError => 'errorType' in response;

const createAiApi = (
  makeLambdaApiRequest: ReturnType<typeof makeMakeApiRequest>,
  aiApiClient: typeof AiApiClient,
  accessToken: string | null,
) => ({
  aiOptimizeSection: async (
    sectionId: string,
    editorContent: EditorContent,
  ): Promise<AiOptimizeSectionResponse> => {
    const jobResponse = await makeLambdaApiRequest(
      `/section/${sectionId}/ai-optimize`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ section: editorContent }),
      },
    );

    const s3Url = jobResponse.jobUrl;

    let s3JobResponse = null;
    while (!s3JobResponse) {
      await sleep(1000);
      const response = await fetch(s3Url);
      if (response.status !== 404) {
        s3JobResponse = await response.json();
      }
    }

    return s3JobResponse;
  },

  aiChartGenerate: createAiChartGenerateApiMethod(aiApiClient, accessToken),

  aiTableKeyTakeaways: async (
    payload: AiTableKeyTakeawaysDTO,
  ): Promise<AiTableKeyTakeawaysResponse> => {
    const jobResponse = await aiApiClient.createTableKeyTakeawaysJob({
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${accessToken}`,
      },
      body: payload,
    });

    switch (jobResponse.status) {
      case 200: {
        if (isAiTableKeyTakeawaysErrorResponse(jobResponse.body)) {
          return jobResponse.body.errorBody;
        }

        const s3Url = jobResponse.body.jobUrl;

        let s3JobResponse: null | {
          data: {
            tableKeyTakeaways: string[];
          };
        } = null;
        for (let count = 0; count < MAX_RETRIES; count++) {
          const sleepDuration = getSleepDuration(count);
          await sleep(sleepDuration);
          const response = await fetch(s3Url);
          if (response.status !== 404) {
            s3JobResponse = await response.json();
            break;
          }
        }

        const keyTakeawaysSchema = z.object({
          data: z.object({
            tableKeyTakeaways: z.array(z.string()),
          }),
        });

        const keyTakeaways = keyTakeawaysSchema.safeParse(s3JobResponse);

        if (!keyTakeaways.success) {
          return { errorType: 'unknown' as const };
        }

        return {
          tableKeyTakeaways: keyTakeaways.data.data.tableKeyTakeaways,
        };
      }
      // adapting from ts-rest returning error codes
      // to our code that expects thrown errors
      default: {
        throw new RequestError(
          'aiApiClient.createTableKeyTakeawaysJob',
          '',
          jobResponse.status,
          jobResponse,
        );
      }
    }
  },
});

export default createAiApi;
