// Chart.tsx
import { ChartBlock } from 'editor-content/ChartBlock.ts';
import { makeChartBlockConfigUiDefaults } from 'editor-content/lib/chartBlockConversions.ts';
import { useEffect, useRef, useState, useCallback } from 'react';
import { cx } from 'styling';
import {
  ChartClickedMessageData,
  ChartConfigMessageData,
  ChartExportMessageData,
  ChartReadyMessageData,
  isClickedMessageData,
  isExportMessageData,
  isReadyMessageData,
} from '../../../../frames/chartjs/messageTypes.ts';
import { useActiveCompany } from '../../../userAndCompany/activeCompanyAtom.tsx';
import Heading from '../Heading.tsx';
import styles from './chart.module.scss';

type ChartProps = {
  block: Pick<ChartBlock, 'id'> & {
    data: Pick<ChartBlock['data'], 'chartData'>; // omit sourceData
  };
  className?: string;
  onClick: () => void;
  showWarning?: boolean;

  getInstanceId?: () => string;
} & (
  | {
      preview: true;
      onHasImage: (imgSrc: string) => void;
    }
  | {
      preview: false;
      onHasImage?: undefined;
    }
);

const Chart: React.FC<ChartProps> = ({
  block,
  className,
  onClick,
  preview,
  onHasImage,
  showWarning = false,
  getInstanceId = () => Date.now().toString(),
}) => {
  const frameRef = useRef<HTMLIFrameElement>(null);
  const [loaded, setLoaded] = useState(false);

  const instanceIdRef = useRef<string>(getInstanceId());

  const chartData = block.data.chartData;
  const { brandColorPrimary } = useActiveCompany();
  const blockId = block.id;

  const sendConfig = useCallback(() => {
    if (!frameRef.current?.contentWindow) return;

    console.log(
      `Sending config for ${blockId} (instance: ${instanceIdRef.current})`,
    );

    const configMessage: ChartConfigMessageData = {
      source: 'chartjsFrame',
      type: 'config',
      data: makeChartBlockConfigUiDefaults(chartData, {
        brandColorPrimary,
      }),
      id: blockId,
      instanceId: instanceIdRef.current,
      preview,
      animate: !loaded,
    };

    frameRef.current.contentWindow.postMessage(configMessage, '*');
    setLoaded(true);
  }, [blockId, chartData, brandColorPrimary, preview, loaded]);

  useEffect(
    () => {
      const currentFrame = frameRef.current;

      const currentInstanceId = instanceIdRef.current;

      const handler = (
        event: MessageEvent<{
          data:
            | ChartClickedMessageData
            | ChartReadyMessageData
            | ChartExportMessageData
            | unknown;
        }>,
      ) => {
        const data = event.data;

        if (
          isReadyMessageData(data) &&
          data.id === blockId &&
          frameRef.current?.contentWindow &&
          (!data.instanceId || data.instanceId === currentInstanceId)
        ) {
          sendConfig();
        }

        if (isExportMessageData(data) && data.id === blockId) {
          onHasImage?.(data.data);
        }

        if (isClickedMessageData(data) && data.id === blockId) {
          onClick();
        }
      };

      window.addEventListener('message', handler);

      return () => {
        window.removeEventListener('message', handler);

        // Tell iframe this instance is being unmounted
        if (currentFrame?.contentWindow) {
          currentFrame.contentWindow.postMessage(
            {
              type: 'cleanup',
              source: 'parent',
              id: blockId,
              instanceId: currentInstanceId,
            },
            '*',
          );
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [blockId],
  );

  return (
    <div className={cx(styles.chartContainer, className)}>
      {!!chartData.title.text.length && (
        <Heading className={styles.chartHeading}>
          <span className={styles.chartSubtitle}>{chartData.title.text}</span>
        </Heading>
      )}

      <iframe
        id={`chart-frame-${blockId}`}
        data-testid="ai-chart-frame"
        src={`/frames/chartjs/index.html?id=${blockId}&instance=${instanceIdRef.current}`}
        sandbox="allow-scripts"
        style={{
          opacity: loaded ? 1 : 0,
          transition: 'opacity 0.3s ease', // Smooth transition to reduce flicker
        }}
        title="Chart"
        ref={frameRef}
        className={styles.chartFrame}
        onClick={onClick}
      />

      {showWarning && (
        <div className={styles.chartWarning}>
          AI responses can include mistakes. Be sure to check for accuracy.
        </div>
      )}
    </div>
  );
};

export default Chart;
