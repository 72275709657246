import ContentSelection, {
  isCollapsed,
} from '../../../../editor/selection/contentSelection/ContentSelection.ts';
import HoverNextToPoint from '../../../../domHelpers/hoverNextTo/HoverNextToPoint.tsx';
import { RefCallback } from 'react';
import { useGetDomRangeFromSelection } from '../../../../editor/selection/contentSelection/selectionHelpers.ts';
import mergeRefs from '../../../../junkDrawer/mergeRefs.ts';
import Point from '../../../../domHelpers/Point.js';
import { useMouseSelectionAtom } from '../../../../editor/selection/useMouseSelection/useMouseSelection.ts';

export const WithHoverNextToSelection: React.FC<{
  children: (ref: RefCallback<HTMLElement>) => React.ReactNode;
  hoverContent: React.ReactNode;
  selection: ContentSelection | null;
}> = ({ children, selection, hoverContent }) => {
  const [mouseSelection] = useMouseSelectionAtom();
  const { ref, getDomRange } = useGetDomRangeFromSelection(selection);

  const showHover =
    selection && !isCollapsed(selection) && mouseSelection.type !== 'selecting';
  return (
    <>
      {showHover && (
        <HoverNextToSelection getDomRange={getDomRange}>
          {hoverContent}
        </HoverNextToSelection>
      )}
      {children(mergeRefs([ref]))}
    </>
  );
};

const HoverNextToSelection: React.FC<{
  children: React.ReactNode;
  getDomRange(): Range | null;
}> = ({ getDomRange, children }) => {
  const getPoint = (container: HTMLElement): Point | null => {
    const selection = getDomRange();
    if (!selection) {
      return null;
    }

    const rect = selection.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();

    return [
      (rect.left + rect.right) / 2 - containerRect.width / 2,
      rect.bottom + 16,
    ];
  };

  return (
    <HoverNextToPoint
      usePortal
      viewportPolicy="position-x"
      containerStyles={{ zIndex: 'initial' }}
      getPoint={getPoint}
    >
      {children}
    </HoverNextToPoint>
  );
};
