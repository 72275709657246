import { TableBlock } from 'editor-content/TableBlock.js';
import React, { useCallback, useLayoutEffect } from 'react';
import styles from './TableFromBlock.module.scss';
import cx from 'classnames';
import { TableClone, useTableCloneSync } from './useTableCloneSync.js';
import { TableRefs, useTableRefs } from './useTableRefs.js';
import TableWithFrozenRegions from './TableWithFrozenRegions.js';
import {
  getCloneTableDimensions,
  MainTableMeasurements,
} from './getCloneTableDimensions.js';
import {
  measureTableBorderOffsets,
  measureTableRowsAndColumns,
} from './TableMeasurements.js';
import { getTableGridDimensions } from './getTableGridDimensions.js';
import { TableSelection } from './TableSelection.js';
import TableScrollShadowContainer from '../TableScrollShadowContainer.js';

const TableFromBlock: React.FC<{
  className?: string;
  block: TableBlock;
  noBorder?: boolean;
  onSelectCell?: (selection: TableSelection) => void;
  selection?: TableSelection | null;
  setCellRefAt?: (
    rowIndex: number,
    columnIndex: number,
  ) => (el: HTMLElement | null) => void;
  highlights?: { commentId: string; selection: TableSelection }[];
  highlightSelectionId?: string | null;
}> = ({
  block,
  noBorder,
  onSelectCell,
  setCellRefAt,
  selection,
  highlights = [],
  highlightSelectionId,
  className,
}) => {
  const { frozenRowCount, frozenColumnCount, numRows, numCols } =
    getTableGridDimensions(block);

  const mainTableRefs = useTableRefs();
  const frozenColumnClone = useTableCloneSync();
  const frozenRowClone = useTableCloneSync();
  const frozenCornerClone = useTableCloneSync();

  useSyncTableDimensions({
    mainTableRefs,
    numCols,
    numRows,
    frozenRowCount,
    frozenColumnCount,
    frozenRowClone,
    frozenColumnClone,
    frozenCornerClone,
  });

  useResetScrollOnFreeze(mainTableRefs, frozenColumnCount, frozenRowCount);

  const syncScroll = useSyncScroll({
    mainTableRefs,
    frozenColumnClone,
    frozenRowClone,
  });

  const { columnCloneBlock, rowCloneBlock, cornerCloneBlock } =
    createTableFrozenClones(block, frozenColumnCount, frozenRowCount);

  return (
    // id is used for linking from pdf
    <div id={block.id} className={styles.frozenPositionContainer}>
      <TableScrollShadowContainer
        ref={mainTableRefs.setScrollContainerEl}
        className={cx(frozenRowCount && styles.tableContainer_withFrozenHeader)}
        onScroll={syncScroll}
      >
        <TableWithFrozenRegions
          ref={mainTableRefs.setTableEl}
          setCellRefAt={setCellRefAt}
          setRowRefAt={mainTableRefs.setRowElAt}
          setColumnRefAt={mainTableRefs.setColumnElAt}
          tableBlock={block}
          drawGridlines={!noBorder}
          className={className}
          selection={selection}
          onSelectCell={onSelectCell}
          highlights={highlights}
          highlightSelectionId={highlightSelectionId}
        />
        {frozenRowCount > 0 && (
          <div
            className={styles.frozenRowClone__container}
            ref={frozenRowClone.setCloneScrollContainerEl}
            data-testid="frozen-row-clone"
            aria-hidden
          >
            <TableWithFrozenRegions
              ref={frozenRowClone.setTableEl}
              setCellRefAt={setCellRefAt}
              setRowRefAt={frozenRowClone.setClonedRowElAt}
              setColumnRefAt={frozenRowClone.setClonedColumnElAt}
              drawGridlines={!noBorder}
              className={cx(styles.frozenRowClone__table, className)}
              tableBlock={rowCloneBlock}
              selection={selection}
              onSelectCell={onSelectCell}
              highlights={highlights}
              highlightSelectionId={highlightSelectionId}
            />
          </div>
        )}
        {frozenColumnCount > 0 && (
          <div
            className={styles.frozenColumnClone__container}
            ref={frozenColumnClone.setCloneScrollContainerEl}
            data-testid="frozen-column-clone"
            aria-hidden
          >
            <TableWithFrozenRegions
              ref={frozenColumnClone.setTableEl}
              setCellRefAt={setCellRefAt}
              setRowRefAt={frozenColumnClone.setClonedRowElAt}
              setColumnRefAt={frozenColumnClone.setClonedColumnElAt}
              drawGridlines={!noBorder}
              className={cx(styles.frozenColumnClone__table, className)}
              tableBlock={columnCloneBlock}
              selection={selection}
              onSelectCell={onSelectCell}
              highlights={highlights}
              highlightSelectionId={highlightSelectionId}
            />
          </div>
        )}
        {frozenColumnCount > 0 && frozenRowCount > 0 && (
          <div
            className={styles.frozenCornerClone__container}
            ref={frozenCornerClone.setCloneScrollContainerEl}
            data-testid="frozen-corner-clone"
            aria-hidden
          >
            <TableWithFrozenRegions
              ref={frozenCornerClone.setTableEl}
              setCellRefAt={setCellRefAt}
              setRowRefAt={frozenCornerClone.setClonedRowElAt}
              setColumnRefAt={frozenCornerClone.setClonedColumnElAt}
              drawGridlines={!noBorder}
              className={cx(styles.frozenCornerClone__table, className)}
              tableBlock={cornerCloneBlock}
              selection={selection}
              onSelectCell={onSelectCell}
              highlights={highlights}
              highlightSelectionId={highlightSelectionId}
            />
          </div>
        )}
      </TableScrollShadowContainer>
    </div>
  );
};

export default TableFromBlock;

function createTableFrozenClones(
  block: TableBlock,
  frozenColumnCount: 1 | 2 | 0,
  frozenRowCount: 1 | 2 | 0,
) {
  const columnClone: TableBlock = {
    ...block,
    data: {
      ...block.data,
      rows: block.data.rows.map((row) => ({
        ...row,
        cells: row.cells.slice(0, frozenColumnCount),
      })),
    },
  };
  const rowClone: TableBlock = {
    ...block,
    data: {
      ...block.data,
      rows: block.data.rows.slice(0, frozenRowCount),
    },
  };
  const cornerClone: TableBlock = {
    ...block,
    data: {
      ...block.data,
      rows: block.data.rows.slice(0, frozenRowCount).map((row) => ({
        ...row,
        cells: row.cells.slice(0, frozenColumnCount),
      })),
    },
  };
  return {
    columnCloneBlock: columnClone,
    rowCloneBlock: rowClone,
    cornerCloneBlock: cornerClone,
  };
}

/**
 * Sync row and column dimensions from main table to clones of frozen regions
 *
 * @param mainTableRefs
 * @param numCols
 * @param numRows
 * @param frozenRowCount
 * @param frozenRowClone
 * @param frozenColumnCount
 * @param frozenColumnClone
 * @param frozenCornerClone
 */
function useSyncTableDimensions({
  mainTableRefs,
  numCols,
  numRows,
  frozenRowCount,
  frozenRowClone,
  frozenColumnCount,
  frozenColumnClone,
  frozenCornerClone,
}: {
  mainTableRefs: TableRefs;
  numCols: number;
  numRows: number;
  frozenRowCount: number;
  frozenColumnCount: number;
  frozenRowClone: TableClone;
  frozenColumnClone: TableClone;
  frozenCornerClone: TableClone;
}) {
  useLayoutEffect(() => {
    if (window.navigator.userAgent.includes('jsdom')) {
      return;
    }

    if (!window.ResizeObserver) {
      console.error('No ResizeObserver, skipping');
      return;
    }

    const mainTable = mainTableRefs.getTableEl();
    if (!mainTable) return;

    const observer = new ResizeObserver(() => {
      // console.log('observed resize');
      const tableGridDimensions = {
        columnCount: numCols,
        rowCount: numRows,
      };
      const mainTableMeasurements: MainTableMeasurements = {
        ...measureTableRowsAndColumns(mainTableRefs, tableGridDimensions),
        ...measureTableBorderOffsets(mainTableRefs),
      };

      [
        frozenRowCount > 0 ? frozenRowClone : null,
        frozenColumnCount > 0 ? frozenColumnClone : null,
        frozenRowCount > 0 && frozenColumnCount > 0 ? frozenCornerClone : null,
      ]
        // we filter out clones that are not enabled in order to not trigger warnings
        .filter((a): a is TableClone => !!a)
        // measure before writing to prevent layout thrash
        .map((clone) => ({
          clone,
          desiredDimensions: getCloneTableDimensions(
            mainTableMeasurements,
            clone.measureBorderOffsets(),
          ),
        }))
        .forEach(({ clone, desiredDimensions }) => {
          clone.setTableDimensions(desiredDimensions);
        });
    });

    observer.observe(mainTable);

    return () => {
      observer.disconnect();
    };
  }, [
    frozenColumnClone,
    frozenCornerClone,
    frozenRowClone,
    mainTableRefs,
    numCols,
    numRows,
    frozenRowCount, // measurement needs to be re-triggered when row/col count changes
    frozenColumnCount,
  ]);
}

function useSyncScroll({
  mainTableRefs,
  frozenColumnClone,
  frozenRowClone,
}: {
  mainTableRefs: TableRefs;
  frozenColumnClone: TableClone;
  frozenRowClone: TableClone;
}) {
  return useCallback(() => {
    const mainScrollContainer = mainTableRefs.getScrollContainerEl();
    if (!mainScrollContainer) return;

    frozenColumnClone.syncScrollTop(mainScrollContainer.scrollTop);
    frozenRowClone.syncScrollLeft(mainScrollContainer.scrollLeft);
  }, [frozenColumnClone, frozenRowClone, mainTableRefs]);
}

/**
 * When the table is reconfigured to change frozen regions, reset scroll.
 *
 * When rendering the frozen region for the first time, the scroll will be out of sync.
 * We can't use scroll sync because the size will not yet have been synced (resizeobserver happens later)
 * @param mainTableRefs
 * @param frozenColumnCount
 * @param frozenRowCount
 */
function useResetScrollOnFreeze(
  mainTableRefs: TableRefs,
  frozenColumnCount: number,
  frozenRowCount: number,
) {
  useLayoutEffect(() => {
    const mainScrollContainer = mainTableRefs.getScrollContainerEl();
    if (!mainScrollContainer) return;
    mainScrollContainer.scrollTop = 0;
    mainScrollContainer.scrollLeft = 0;
  }, [mainTableRefs, frozenColumnCount, frozenRowCount]);
}
