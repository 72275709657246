import { Comment } from 'api-client/types.js';
import { Block } from 'editor-content/Block.js';
import { isCommentWithSelection } from './isCommentWithSelection.js';
import {
  findCommentSelectionInSection,
  SelectionCommentContentPosition,
} from './findCommentSelectionInSection.js';

export type CommentQuoteStatus =
  | 'none'
  | SelectionCommentContentPosition['type'];

export const getCommentQuoteStatus = (
  comment: Comment,
  section: { body: Block[] },
): CommentQuoteStatus => {
  if (!isCommentWithSelection(comment)) return 'none';

  return findCommentSelectionInSection(comment, section).type;
};
