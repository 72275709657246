import React from 'react';
import cx from 'classnames';
import File from '../../design-system/zeck/File.tsx';
import useApi from '../../api/useApi.ts';
import { FileBlock } from 'editor-content/FileBlock.js';
import styles from './FileWithDownload.module.scss';

type FileProps = {
  className?: string;
  block: FileBlock;
  onPreview?: (pdfUrl: string) => void;
};

const FileWithDownload = React.forwardRef<HTMLButtonElement, FileProps>(
  function FileWithDownload({ className, block, onPreview }, forwardedRef) {
    const { getFile } = useApi();

    return (
      <button
        // id is used for linking from pdf
        id={block.id}
        className={cx(styles.fileWithDownload, className)}
        onClick={async () => {
          const fileGetData = await getFile(block.guid);

          if (fileGetData?.readUrl) {
            window.location.href = fileGetData.readUrl;
          }
        }}
        ref={forwardedRef}
      >
        <File
          {...{
            clickable: true,
            filename: block.filename,
            onPreview: async (e) => {
              e.preventDefault();
              e.stopPropagation();
              const fileGetData = await getFile(block.guid);
              onPreview?.(fileGetData.readUrl);
            },
          }}
        />
      </button>
    );
  },
);

export default FileWithDownload;
