import { HydratedBlock } from 'app/types/HydratedBlock.ts';
import { BodyState } from './BodyEditor.ts';
import { textSelection } from '../../../../editor/selection/TextSelection.js';
import { contentSelection } from '../../../../editor/selection/contentSelection/ContentSelection.js';

const addNewBlocksAtIndex = function (
  initialState: BodyState,
  insertAt: number,
  newBlocks: HydratedBlock[],
) {
  if (insertAt < 0) {
    throw new Error('insertAt is negative');
  }

  const content = initialState.content;
  // we allow insertAt to be greater than the length of the content
  // but we need to make sure that we set the correct text selection index
  const textSelectionIndex =
    insertAt <= content.length - 1 ? insertAt : content.length;

  return {
    content: [
      ...content.slice(0, insertAt),
      ...newBlocks,
      ...content.slice(insertAt),
    ],
    selection: textSelection(textSelectionIndex, contentSelection(0)),
  };
};

export default addNewBlocksAtIndex;
