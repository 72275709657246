import { atomFamily } from 'jotai/utils';
import { atom, Atom } from 'jotai';
import { Block } from 'editor-content/Block.js';
import { Comment } from 'api-client/types.js';
import { findCommentSelectionInSection } from './findCommentSelectionInSection.js';
import { PublishedCommentHighlight } from './SelectionComments.js';
import { match, P } from 'ts-pattern';

type CommentWithSelection = Omit<Comment, 'selection'> & {
  selection: Exclude<Comment['selection'], null>;
};

export const createBlockHighlightsAtomFamily = (
  commentsAtom: Atom<Comment[]>,
  section: {
    body: Block[];
  },
) =>
  atomFamily((blockId: string) =>
    atom((get) =>
      get(commentsAtom)
        .filter(
          (comment): comment is CommentWithSelection =>
            !!comment.selection && comment.selection.block.id === blockId,
        )
        .map((comment): null | PublishedCommentHighlight => {
          const foundCommentSelection = findCommentSelectionInSection(
            comment,
            section,
          );

          if (foundCommentSelection.type === 'orphaned') return null;

          return {
            ...match(foundCommentSelection)
              .with(
                { blockIndex: P.number }, // remove block index
                ({ blockIndex: _, ...other }) => other,
              )
              .otherwise((a) => a),
            commentId: comment.id,
          };
        })
        .filter((a) => !!a),
    ),
  );
