import {
  createDefaultBlock,
  filterMeetingMinutesBlocks,
  meetingMinutesBlockToBlockEditor,
  turnIntoMeetingMinutesBlock,
} from '../MeetingMinutes.ts';
import styles from './MeetingMinutesEditorPageView.module.scss';
import zeckStyles from '../../../design-system/zeck/ZeckStyles.module.scss';
import highlightStyles from '../../zeck/highlights/highlights.module.scss';
import { User } from '../../../types.ts';
import { EditorCoordinator } from '../../../editor/EditorCoordinator.ts';
import { useEffect, useRef, useSyncExternalStore } from 'react';
import CopyPaste from '../../../editor/CopyPaste.ts';
import MeetingMinutesTopBar from './components/MeetingMinutesTopBar.tsx';
import { useDebouncedSave } from '../../zeck/edit/withEditorSave.tsx';
import isEqual from 'lodash/isEqual.js';
import useViewportHeight from '../../../services/useViewportHeight.ts';
import cx from 'classnames';
import EditorDebugOverlay from './components/EditorDebugOverlay.tsx';
import { useFeatureFlags } from '../../../feature-flags/FeatureFlagsContext.ts';
import MeetingMinutesEditable from './components/MeetingMinutesEditable.tsx';
import {
  MeetingMinutes,
  MinutesSignature,
} from '../../../api/endpoints/createMeetingMinutesApi.ts';
import MeetingMinutesSignatureFlow from './components/MeetingMinutesSignatureFlow.tsx';
import MeetingMinutesPageLayout from './components/MeetingMinutesPageLayout.tsx';

export default function MeetingMinutesEditorPageView(props: {
  meetingMinutes: MeetingMinutes;
  company: { id: string };
  user: User;
  signMeetingMinutes: (
    meetingMinutes: Pick<MeetingMinutes, 'id' | 'version'>,
    signature: MinutesSignature,
  ) => Promise<void>;
  onUpdateMeetingMinutes: (params: {
    content: MeetingMinutes['content'];
  }) => Promise<void>;
  activeUsers: User[];
  scrollContainer: React.RefObject<HTMLDivElement>;
}) {
  const { editorDebug } = useFeatureFlags();
  const scrollContainer = props.scrollContainer;

  const editor = useRef(
    new EditorCoordinator(
      {
        content: props.meetingMinutes.content,
        selection: null,
      },
      {
        createDefaultBlock: createDefaultBlock,
        generateBlockEditor: meetingMinutesBlockToBlockEditor,
        turnInto: turnIntoMeetingMinutesBlock,
      },
    ),
  );

  const copyPaste = useRef(
    new CopyPaste(
      {
        cut() {
          return editor.current.cut();
        },
        copy() {
          return editor.current.copy();
        },
        dispatch(event) {
          switch (event.type) {
            case 'pasteBlocks': {
              return editor.current.dispatch({
                ...event,
                data: filterMeetingMinutesBlocks(event.data),
              });
            }
            default: {
              return editor.current.dispatch(event);
            }
          }
        },
      },
      props.company.id,
    ),
  );

  const isSaved = useSyncExternalStore(
    (callback) => editor.current.subscribeToContentChanges(callback),
    () =>
      isEqual(editor.current.getState().content, props.meetingMinutes.content),
  );

  useViewportHeight(scrollContainer);

  useEffect(() => {
    // we would like to add the handlers directly to the wrapping div if we could, but paste events outside contentEditable (block paste) comes from body and we aren't sure why yet
    return copyPaste.current.addHandlers(document);
  }, []);

  const debouncedSaveOnUpdate = useDebouncedSave(
    editor.current.getState().content,
    (newContent) => {
      props.onUpdateMeetingMinutes({
        content: newContent,
      });
    },
  );

  useEffect(() => {
    let content = editor.current.getState().content;

    return editor.current.subscribeToContentChanges(() => {
      const newContent = editor.current.getState().content;
      if (!isEqual(newContent, content)) {
        content = newContent;
        debouncedSaveOnUpdate(newContent);
      }
    });
  }, [debouncedSaveOnUpdate]);

  const allAvailableTags = props.activeUsers.map(
    ({ id, firstName, lastName }) => ({
      userId: id,
      displayName: `${firstName} ${lastName}`,
    }),
  );

  const minutesListLink = `/company/${props.company.id}/minutes`;
  return (
    <MeetingMinutesPageLayout
      headerSlot={
        <MeetingMinutesTopBar
          minutesListLink={minutesListLink}
          isSaved={isSaved}
          title={props.meetingMinutes.title}
        />
      }
      scrollContainerRef={scrollContainer}
    >
      {editorDebug && <EditorDebugOverlay editor={editor.current} />}

      <div
        className={cx(
          zeckStyles.zeck,
          styles.editStyles,
          highlightStyles.hasHighlights,
        )}
      >
        <MeetingMinutesEditable
          editor={editor.current}
          allAvailableTags={allAvailableTags}
          additionalContent={
            <MeetingMinutesSignatureFlow
              meetingMinutes={props.meetingMinutes}
              signMeetingMinutes={props.signMeetingMinutes}
              user={props.user}
            />
          }
          getScrollContainer={() => scrollContainer.current}
        />
      </div>
    </MeetingMinutesPageLayout>
  );
}
