import cx from 'classnames';
import initials from 'initials';
import CommentVisibilityHelp from '../../../../../pages/zeck/previewAndPublish/publish/commentsSidebar/CommentVisibilityHelp.tsx';
import PublishedCommentForm from '../../../../../pages/zeck/previewAndPublish/publish/commentsSidebar/PublishedCommentForm.tsx';
import {
  isNotPendingUser,
  MaybePendingUser,
} from '../../../../../types/User.ts';
import styles from './PublishedSelectionCommentForm.module.scss';
import DetectsOutsideClick from '../../../../../junkDrawer/DetectsOutsideClick.tsx';
import UserInitials from '../../../../../design-system/atoms/UserInitials.tsx';
import useBreakpoints from '../../../../../services/useBreakpoints.ts';
import { CommentContent } from 'editor-content/CommentContent.js';

type Props = {
  onClose: () => void;
  onSubmit: (commentInfo: {
    content: CommentContent;
    isDirectMessage: boolean;
  }) => Promise<void>;
  currentUserName: string;
  viewers: MaybePendingUser[];
  className?: string;
};

const PublishedSelectionCommentForm = ({
  onClose,
  onSubmit,
  currentUserName,
  viewers,
  className,
}: Props) => {
  const { isMobile } = useBreakpoints();

  const availableTags =
    viewers.filter(isNotPendingUser).map((u) => ({
      userId: u.id,
      displayName: `${u.firstName} ${u.lastName}`,
    })) ?? [];

  const placeholder = isMobile
    ? 'Add a comment...'
    : 'Add a comment or question...';

  return (
    <DetectsOutsideClick
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClose();
      }}
    >
      {(ref) => {
        return (
          <div
            ref={ref}
            className={cx(
              styles.publishedSelectionCommentForm,
              isMobile && styles.publishedSelectionCommentForm__mobile,
              className,
            )}
            onClick={(e) => e.stopPropagation()}
          >
            <div className={styles.publishedSelectionCommentForm__initials}>
              <UserInitials size="medium">
                {initials(currentUserName)}
              </UserInitials>
            </div>
            <div className={styles.publishedSelectionCommentForm__form}>
              <PublishedCommentForm
                testId="published-comment-form"
                placeholder={placeholder}
                renderHelp={({ isDirectMessage }) =>
                  isDirectMessage ? null : (
                    <CommentVisibilityHelp viewers={viewers} />
                  )
                }
                mini
                availableTags={availableTags}
                onCancel={onClose}
                submitText="Comment"
                onSubmit={onSubmit}
                initialValue={{
                  content: [],
                  isDirectMessage: false,
                }}
                canToggleDirectMessage={true}
              />
            </div>
          </div>
        );
      }}
    </DetectsOutsideClick>
  );
};

export default PublishedSelectionCommentForm;
