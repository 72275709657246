import styles from './CommentQuote.module.scss';
import { getTextFromNodes, TextNode } from 'editor-content/TextNode.js';
import React from 'react';
import { CommentQuoteStatus } from '../../useComments/selectionComments/CommentQuoteStatus.js';

type TableBlockQuoteSnippet = {
  type: 'table-cell';
  id: string;
  content: TextNode[];
};

export const QuotedTableBlock: React.FC<{
  block: TableBlockQuoteSnippet;
  quoteStatus: CommentQuoteStatus;
}> = ({ block, quoteStatus }) => {
  return (
    <div data-testid="comment-quote" className={styles.commentOrReply__quote}>
      {getTextFromNodes(block.content)}
      {quoteStatus === 'disowned' && (
        <div className={styles.commentOrReply__quoteStatus}>
          (Table was edited or changed since this was posted)
        </div>
      )}
      {quoteStatus === 'orphaned' && (
        <div className={styles.commentOrReply__quoteStatus}>
          (Table was moved or deleted since this was posted)
        </div>
      )}
    </div>
  );
};
