import React, { useState } from 'react';
import {
  VoteDetails,
  VoteHeading,
  VoteResultPill,
} from '../../../design-system/zeck/vote/Vote.tsx';
import styles from '../../../design-system/zeck/vote/Vote.module.scss';
import formatVotedAt from '../../voting/formatVotedAt.ts';
import EditVoteButton from './EditVoteButton.tsx';
import cx from 'classnames';
import EditVoteModal from './EditVoteModal.tsx';
import ConfirmEditVoteModal from './ConfirmEditVoteModal.tsx';
import { CompletedVoteBlock } from 'editor-content/VoteBlock.js';
import VoteLayout from '../../../design-system/zeck/vote/VoteLayout.tsx';
import Vote from '../../../types/Vote.ts';

type CompletedVoteViewProps = {
  className?: string;
  block: CompletedVoteBlock;
  editVote:
    | ((
        vote: Pick<
          Vote,
          'title' | 'details' | 'blockId' | 'approved' | 'voteType'
        >,
      ) => Promise<void>)
    | null;
};

const CompletedVoteView = React.forwardRef<
  HTMLDivElement,
  CompletedVoteViewProps
>(function CompletedVoteView({ block, editVote, ...otherProps }, forwardedRef) {
  const [editing, setEditing] = useState(false);
  const [pendingEdits, setPendingEdits] = useState<null | {
    title: string;
    details: string;
    approved: boolean;
  }>(null);

  return (
    <>
      <VoteLayout
        ref={forwardedRef}
        // id is used for linking from pdf
        id={block.id}
        {...{
          ...otherProps,
          titleContent: <VoteHeading>{block.title || 'Untitled'}</VoteHeading>,
        }}
      >
        {block.details && (
          <VoteDetails data-testid="vote-details">{block.details}</VoteDetails>
        )}
        <div className={cx(styles.voteBody, styles.voteBody_completed)}>
          <VoteResultPill approved={block.approved} />
          <span className={styles.votedAt}>{formatVotedAt(block.votedAt)}</span>
          {!!editVote && (
            <EditVoteButton
              onClick={() => {
                setEditing(true);
              }}
              className={styles.editVoteButton}
            />
          )}
        </div>
      </VoteLayout>
      <EditVoteModal
        isOpen={editing}
        onClose={() => {
          setEditing(false);
        }}
        voteTitle={block.title}
        voteDetails={block.details}
        voteApproved={block.approved}
        onSave={(edits) => {
          setEditing(false);
          setPendingEdits(edits);
        }}
      />
      <ConfirmEditVoteModal
        isOpen={Boolean(pendingEdits)}
        onCancel={() => {
          setPendingEdits(null);
        }}
        onSave={() => {
          setPendingEdits(null);
          if (!pendingEdits || !editVote) {
            return;
          }
          editVote({
            ...pendingEdits,
            voteType: block.voteType,
            blockId: block.id,
          });
        }}
      />
    </>
  );
});

export default CompletedVoteView;
