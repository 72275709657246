import React from 'react';
import styles from './BlockLayout.module.scss';
import highlightStyles from '../../pages/zeck/highlights/highlights.module.scss';
import cx from 'classnames';

type BlockLayoutProps = {
  className?: string;
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

const BlockLayout = React.forwardRef<HTMLDivElement, BlockLayoutProps>(
  function BlockLayout({ className, children, ...otherProps }, forwardedRef) {
    return (
      <div
        {...otherProps}
        ref={forwardedRef}
        data-testid="document-container"
        className={cx(
          styles.blockLayout,
          highlightStyles.hasHighlights,
          className,
        )}
      >
        {children}
      </div>
    );
  },
);

export default BlockLayout;
