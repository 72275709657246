import React from 'react';
import cx from 'classnames';
import styles from './WideWidthBlockContainer.module.scss';

type WideWidthBlockContainerProps = {
  children: React.ReactNode;
  id?: string;
  className?: string;
};

export const WideWidthBlockContainer: React.FC<
  WideWidthBlockContainerProps
> = ({ children, id, className }) => (
  <div id={id} className={cx(className, styles.wideWidthBlockContainer)}>
    {children}
  </div>
);

export default WideWidthBlockContainer;
