import useApi from '../../api/useApi.js';
import useFetchZeck from './useFetchZeck.js';
import { useState } from 'react';
import usePrevious from '../../junkDrawer/usePrevious.js';

const useFetchZeckForSection = (sectionId: string, fullBody = true) => {
  const { fetchZeckForSection } = useApi();
  const [isReloading, setIsReloading] = useState(false);

  const sectionIdPrevious = usePrevious(sectionId);

  return {
    zeckResult: useFetchZeck(async () => {
      if (sectionIdPrevious && sectionId !== sectionIdPrevious) {
        setIsReloading(true);
      }

      const zeck = await fetchZeckForSection(sectionId, fullBody);

      setIsReloading(false);
      return zeck;
    }, [sectionId]),
    isReloading,
  };
};

export default useFetchZeckForSection;
