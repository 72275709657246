import React, { useState } from 'react';
import usePlaintextEditable from '../usePlaintextEditable.ts';
import {
  VoteDetails,
  VoteHeading,
} from '../../../../design-system/zeck/vote/Vote.tsx';
import { OutstandingVoteBlock } from 'editor-content/VoteBlock.js';
import { VoteType } from 'blocks/src/VoteBlock.js';
import Tooltip from '../../../../design-system/organisms/Tooltip.tsx';
import Dropdown from '../../../../design-system/molecules/Dropdown.tsx';
import keyDownHandlerLetsInputsWork from './keyDownHandlerLetsInputsWork.ts';
import {
  FinalizeVoteCapability,
  PrevoteCapability,
} from '../../voting/VoteCapability.js';
import OutstandingVote from '../../../../design-system/zeck/OutstandingVote.js';
import KebabMenuButton from '../../../../design-system/organisms/KebabMenuButton.js';
import { rightAlignedBelow } from '../../../../domHelpers/hoverNextTo/positionStrategy/rightAlignedBelow.js';

const voteTypeOptions = [
  {
    value: VoteType.MeetingMinutesApproval,
    label: 'Approval of Previous Meeting Minutes',
  },
  {
    value: VoteType.StockOptionApproval,
    label: 'Approval of Stock Option Grant',
  },
  { value: VoteType.ValuationApproval, label: 'Approval of 409A Valuation' },
  { value: VoteType.Other, label: 'Other' },
];

const VoteTypePicker = ({
  onChangeVoteType,
  block,
}: {
  block: OutstandingVoteBlock;
  onChangeVoteType: (newVoteType: VoteType) => void;
}) => {
  const [tooltipActive, setTooltipActive] = useState(true);
  return (
    <Tooltip
      active={tooltipActive}
      tipText={'Sets the vote template to be used in meeting minutes'}
    >
      <Dropdown
        options={voteTypeOptions}
        onChange={(val) => {
          onChangeVoteType(val);
        }}
        onMenuOpen={() => setTooltipActive(false)}
        onMenuClose={() => setTooltipActive(true)}
        placeholder="Select Vote Type (optional)"
        value={block.voteType === VoteType.None ? undefined : block.voteType}
      />
    </Tooltip>
  );
};

const VoteKebabMenu: React.FC<{
  prevoteEnabled: boolean;
  onChangePrevoteEnabled: (prevoteEnabled: boolean) => void;
}> = ({ prevoteEnabled, onChangePrevoteEnabled }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <KebabMenuButton
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      iconName="kebab"
      positionStrategy={rightAlignedBelow(8)}
      items={[
        prevoteEnabled
          ? {
              label: 'Disable Pre-Voting',
              iconName: 'prohibition',
              onClick: () => {
                onChangePrevoteEnabled(false);
                setIsOpen(false);
              },
            }
          : {
              label: 'Enable Pre-Voting',
              iconName: 'vote',
              onClick: () => {
                onChangePrevoteEnabled(true);
                setIsOpen(false);
              },
            },
      ]}
      ariaLabel={'vote settings'}
    />
  );
};

type OutstandingVoteEditableProps = {
  className?: string;
  block: OutstandingVoteBlock;
  onChange: (newValue: OutstandingVoteBlock) => void;
  tabIndex?: number;
  prevoteCapability: PrevoteCapability | null;
  finalizeVoteCapability: FinalizeVoteCapability | null;
};

const OutstandingVoteEditable = React.forwardRef<
  HTMLDivElement,
  OutstandingVoteEditableProps
>(function OutstandingVoteEditable(
  { block, onChange, prevoteCapability, finalizeVoteCapability },
  forwardedRef,
) {
  const titleEditableProps = usePlaintextEditable<HTMLDivElement>(
    block.title,
    (title) => onChange({ ...block, title }),
  );
  const detailsEditableProps = usePlaintextEditable<HTMLDivElement>(
    block.details,
    (details) => onChange({ ...block, details }),
  );

  const kebabContent = finalizeVoteCapability && (
    <VoteKebabMenu
      prevoteEnabled={block.prevoteEnabled}
      onChangePrevoteEnabled={(prevoteEnabled) =>
        onChange({
          ...block,
          prevoteEnabled,
        })
      }
    />
  );

  return (
    <OutstandingVote
      ref={forwardedRef}
      block={block}
      prevoteCapability={prevoteCapability}
      finalizeVoteCapability={finalizeVoteCapability}
      onKeyDown={keyDownHandlerLetsInputsWork}
      titleContent={<VoteHeading {...titleEditableProps} />}
      detailsContent={
        <VoteDetails data-testid="vote-details" {...detailsEditableProps} />
      }
      additionalContent={
        <VoteTypePicker
          key="voteType"
          block={block}
          onChangeVoteType={(voteType) => onChange({ ...block, voteType })}
        />
      }
      kebabContent={kebabContent}
    />
  );
});

export default OutstandingVoteEditable;
