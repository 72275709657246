import {
  isTableBlockCellUncompressed,
  MAX_TABLE_COLUMNS,
  MAX_TABLE_ROWS,
  TableBlock,
  TableBlockCell,
  TableBlockCellBorderFormat,
  TableBlockCellFormat,
  TableBlockRow,
  TableBorderType,
} from 'editor-content/TableBlock.ts';
import { Border, Cell, Row, ValueType, Workbook, Worksheet } from 'exceljs';
import getTextNodesFromCell from './getTextNodesFromCell.js';
import getCellType from './getCellType.js';
import cellHasNoContentsDueToMerge from './cellHasNoContentDueToMerge.js';
import normalizeTableData from '../normalizeTableData.js';
import {
  validateAndLogTableErrors,
  createFrozenDimensions,
} from '../tableUtils.js';

const getVerticalAlign = (
  cell: Cell,
): Pick<TableBlockCellFormat, 'alignVertical'> => {
  const verticalMap: Record<string, 'top' | 'middle' | 'bottom'> = {
    top: 'top',
    middle: 'middle',
    bottom: 'bottom',
  };

  return {
    alignVertical: verticalMap[cell.alignment?.vertical ?? ''] ?? 'bottom',
  };
};

const getFrozenData = (
  worksheet: Worksheet,
): Pick<TableBlock, 'frozenColumns' | 'frozenRows'> => {
  // this is an array but only the first element is applied
  const firstView = worksheet.views?.[0];

  // @ts-expect-error - xSplit is not typed
  const frozenColumns = firstView?.xSplit;
  // @ts-expect-error - ySplit is not typed
  const frozenRows = firstView?.ySplit;

  return createFrozenDimensions(frozenRows, frozenColumns);
};

const getHorizontalAlign = (
  cell: Cell,
  valueTypes: typeof ValueType,
): Pick<TableBlockCellFormat, 'alignHorizontal'> => {
  switch (cell.alignment?.horizontal) {
    case 'left':
      return {
        alignHorizontal: 'left',
      };
    case 'center':
      return {
        alignHorizontal: 'center',
      };
    case 'right':
      return {
        alignHorizontal: 'right',
      };
    default:
      return {
        alignHorizontal:
          getCellType(cell) === valueTypes.Number ? 'right' : 'left',
      };
  }
};

const getCellWrap = (cell: Cell): Pick<TableBlockCellFormat, 'wrap'> => {
  switch (cell.alignment?.wrapText) {
    case true:
      return {
        wrap: 'wrap',
      };
    default:
      return {
        wrap: 'clip',
      };
  }
};

const getCellBorderValue = (
  borderValue: Partial<Border> | undefined,
): TableBorderType => {
  if (!borderValue?.style) {
    return undefined;
  }

  switch (borderValue.style) {
    case 'double':
      return 'thick';
    case 'thick':
      return 'thick';
    default:
      return 'thin';
  }
};

const getCellBorders = (
  cell: Cell,
  endCell?: Cell,
): TableBlockCellBorderFormat => {
  return {
    top: getCellBorderValue(cell?.border?.top),
    right: getCellBorderValue(cell?.border?.right ?? endCell?.border?.right),
    bottom: getCellBorderValue(cell?.border?.bottom ?? endCell?.border?.bottom),
    left: getCellBorderValue(cell?.border?.left),
  };
};

const getCellFormatting = (
  cell: Cell,
  valueTypes: typeof ValueType,
  endCell?: Cell,
): TableBlockCellFormat | undefined => {
  if (cellHasNoContentsDueToMerge(cell, valueTypes)) {
    return undefined;
  }

  if (
    !cell.alignment &&
    getCellType(cell) !== valueTypes.Number &&
    !cell?.style?.border
  ) {
    return undefined;
  }

  const alignVertical = getVerticalAlign(cell);
  const alignHorizontal = getHorizontalAlign(cell, valueTypes);
  const wrap = getCellWrap(cell);
  const border = getCellBorders(cell, endCell);

  return {
    ...alignVertical,
    ...alignHorizontal,
    ...wrap,
    border,
  };
};

type MergeData = {
  cell: Cell;
  otherCells: Cell[];
};

const getCellPosition = (cell: Cell) => ({
  row: parseInt(cell.row, 10),
  col: parseInt(cell.col, 10),
});

const processMergeData = (
  possibleMerges: MergeData[],
  activeRange: ActiveRange,
) => {
  return possibleMerges.map((possibleMerge) => {
    const { row: startRow, col: startColumn } = getCellPosition(
      possibleMerge.cell,
    );

    const endCell = possibleMerge.otherCells.reduce((endpoint, currentCell) => {
      const { row, col } = getCellPosition(currentCell);
      const { row: endpointRow, col: endPointCol } = getCellPosition(endpoint);

      if (row > endpointRow || col > endPointCol) {
        return currentCell;
      }

      return endpoint;
    }, possibleMerge.cell);

    const { col: maxCol, row: maxRow } = getCellPosition(endCell);

    return {
      startColumn: startColumn - activeRange.startColumn,
      columnSpan: maxCol - (startColumn - 1),
      startRow: startRow - activeRange.startRow,
      rowSpan: maxRow - (startRow - 1),
      endCell,
    };
  });
};

const updateMergeData = (cell: Cell, mergeData: MergeData[]): MergeData[] => {
  if (!cell.isMerged) {
    return mergeData;
  }

  const existingMergeIndex = mergeData.findIndex((merge) =>
    cell.isMergedTo(merge.cell),
  );

  if (existingMergeIndex >= 0) {
    return mergeData.map((merge, index) =>
      index === existingMergeIndex
        ? { ...merge, otherCells: [...merge.otherCells, cell] }
        : merge,
    );
  }

  return [...mergeData, { cell, otherCells: [] }];
};

export type WorksheetRange = {
  startRow: number; // 1 indexed
  endRow: number | null;
  startColumn: number; // 1 indexed
  endColumn: number | null;
};

type ActiveRange = {
  startRow: number; // 1 indexed
  endRow: number;
  startColumn: number; // 1 indexed
  endColumn: number;
};

const getActiveRange = (
  worksheet: Worksheet,
  range: WorksheetRange,
): ActiveRange => ({
  startRow: range.startRow,
  startColumn: range.startColumn,
  endRow: Math.min(
    range.endRow ?? worksheet.rowCount,
    range.startRow + MAX_TABLE_ROWS - 1,
  ),
  endColumn: Math.min(
    range.endColumn ?? worksheet.columnCount,
    range.startColumn + MAX_TABLE_COLUMNS - 1,
  ),
});

const isRowOutsideActiveRange = (row: Row, range: ActiveRange): boolean => {
  return row.number < range.startRow || row.number > range.endRow;
};

const isCellOutsideActiveRange = (cell: Cell, range: ActiveRange): boolean => {
  const column = parseInt(cell.col, 10);
  return column < range.startColumn || column > range.endColumn;
};

const getColumnWidths = (
  worksheet: Worksheet,
  columnOffset: number,
): Record<number, number> => {
  // default excel column width is 8.43
  // equates to 64 pixels
  const pixelToCharRatio = 64 / 8.43;
  const columnWidths: Record<number, number> = {};

  if (!worksheet.columns) {
    return columnWidths;
  }

  worksheet.columns.forEach((column, i) => {
    if (column.isCustomWidth && column.width) {
      columnWidths[i - columnOffset] = Math.round(
        column.width * pixelToCharRatio,
      );
    }
  });

  return columnWidths;
};

export const getDefinedNameData = (
  workbook: Workbook,
  rangeName: string,
): { range: WorksheetRange; sheetName: string } | undefined => {
  const rangeString = workbook.definedNames.getRanges(rangeName)?.ranges?.[0];

  if (!rangeString) {
    return undefined;
  }

  const [sheetName, cellAddresses] = rangeString.split('!');
  if (!sheetName || !cellAddresses) {
    return undefined;
  }

  const parsedSheetName = sheetName.replaceAll("'", '');

  const worksheet = workbook.getWorksheet(parsedSheetName);
  const [startAddress, endAddress] = cellAddresses.split(':');
  if (!startAddress || !endAddress || !worksheet) {
    return undefined;
  }
  const startCell = worksheet.getCell(startAddress);
  const endCell = worksheet.getCell(endAddress);

  return {
    range: {
      startColumn: parseInt(startCell.col, 10),
      startRow: parseInt(startCell.row, 10),
      endColumn: parseInt(endCell.col, 10),
      endRow: parseInt(endCell.row, 10),
    },
    sheetName: parsedSheetName,
  };
};

const removeTrailingEmptyRows = (rows: TableBlockRow[]): TableBlockRow[] => {
  const hasCellContent = (cell: TableBlockCell): boolean => {
    if (isTableBlockCellUncompressed(cell)) {
      const hasContent = cell.content.some((c) => c.text.length > 0);
      return hasContent || !!cell.format?.border;
    }
    return cell !== '';
  };

  const hasRowContent = (row: TableBlockRow): boolean =>
    row && row.cells.some(hasCellContent);

  const lastNonEmptyIndex = [...rows].reverse().findIndex(hasRowContent);

  if (lastNonEmptyIndex === -1) {
    return rows;
  }

  const keepCount = rows.length - lastNonEmptyIndex;
  return rows.slice(0, keepCount);
};

export default function createTableFromExcelWorksheet(
  worksheet: Worksheet | undefined,
  valueTypes: typeof ValueType,
  initialRange: WorksheetRange = {
    startColumn: 1,
    endColumn: null,
    startRow: 1,
    endRow: null,
  },
): Pick<TableBlock, 'data' | 'frozenColumns' | 'frozenRows'> {
  if (!worksheet) {
    return {
      data: {
        rows: [],
        merges: [],
      },
    };
  }

  const range = {
    ...initialRange,
    startColumn: Math.max(
      initialRange.startColumn,
      worksheet?.dimensions?.left ?? 0,
    ),
    startRow: Math.max(initialRange.startRow, worksheet?.dimensions?.top ?? 0),
  };

  const activeRange = getActiveRange(worksheet, range);
  let mergeData: MergeData[] = [];
  const rows: TableBlockRow[] = [];
  const rowHeights: Record<number, number> = {};

  worksheet.eachRow({ includeEmpty: true }, (row) => {
    if (isRowOutsideActiveRange(row, activeRange)) {
      return;
    }

    row.eachCell({ includeEmpty: true }, (cell) => {
      if (isCellOutsideActiveRange(cell, activeRange)) {
        return;
      }
      mergeData = updateMergeData(cell, mergeData);
    });
  });
  const merges = processMergeData(mergeData, activeRange);

  worksheet.eachRow({ includeEmpty: true }, (row) => {
    if (isRowOutsideActiveRange(row, activeRange)) {
      return;
    }

    // don't believe the types, this can be and defaults to undefiend
    if (row.height) {
      // row height is in points, convert to pixels
      rowHeights[rows.length] = Math.round(row.height * 1.3333);
    }

    const cells: TableBlockCell[] = [];
    row.eachCell({ includeEmpty: true }, (cell) => {
      if (isCellOutsideActiveRange(cell, activeRange)) {
        return;
      }

      const textNodes = getTextNodesFromCell(cell, valueTypes);
      const { col, row } = getCellPosition(cell);
      const merge = merges.find(
        (merge) =>
          merge.startColumn === col - activeRange.startColumn &&
          merge.startRow === row - activeRange.startRow,
      );
      const newCell = getCellFormatting(cell, valueTypes, merge?.endCell);

      cells.push(TableBlockCell(textNodes, newCell));
    });

    rows.push(TableBlockRow(cells));
  });

  const data = normalizeTableData({
    rows: removeTrailingEmptyRows(rows),
    merges: merges.map((m) => ({
      startRow: m.startRow,
      startColumn: m.startColumn,
      rowSpan: m.rowSpan,
      columnSpan: m.columnSpan,
    })),
    columnWidths: getColumnWidths(worksheet, range.startColumn - 1),
    rowHeights,
  });

  return validateAndLogTableErrors({
    data,
    ...getFrozenData(worksheet),
  });
}
