import cx from 'classnames';
import styles from './DisownedCommentIndicator.module.scss';
import { DisownedHighlight } from '../../../highlights/DisownedHighlight.js';
import React from 'react';
import CommentIndicator from './CommentIndicator.js';
import { Atom, useAtomValue } from 'jotai';
import { PublishedCommentHighlight } from '../commentsSidebar/useComments/selectionComments/SelectionComments.js';

export const DisownedCommentIndicator: React.FC<{
  className?: string;
  disownedCommentIds: string[];
  commentCount: number;
  onClickCommentIndicator: () => void;
  children: React.ReactNode;
  extraSpacingForListItem?: boolean;
  isSelected?: boolean;
}> = ({
  className,
  children,
  disownedCommentIds,
  commentCount,
  onClickCommentIndicator,
  extraSpacingForListItem,
  isSelected,
}) => {
  if (disownedCommentIds.length === 0) {
    return <div className={className}>{children}</div>;
  }

  return (
    <div
      className={cx(
        styles.disownedCommentIndicator,
        isSelected && styles.disownedCommentIndicator_selected,
        extraSpacingForListItem && styles.disownedCommentIndicator_listItem,
        className,
      )}
    >
      <DisownedHighlight highlightIds={disownedCommentIds} />
      <div className={styles.disownedCommentIndicator__mainContent}>
        {children}
      </div>
      <CommentIndicator
        className={styles.disownedCommentIndicator__commentIndicator}
        onClick={onClickCommentIndicator}
        commentCount={commentCount}
      />
    </div>
  );
};

export const DisownedCommentIndicatorFromAtom: React.FC<{
  className?: string;
  children: React.ReactNode;
  extraSpacingForListItem?: boolean;
  onClickCommentIndicator: () => void;
  highlightsAtom: Atom<PublishedCommentHighlight[]>;
  selectedCommentIdAtom: Atom<string | null>;
}> = ({
  className,
  children,
  extraSpacingForListItem,
  onClickCommentIndicator,
  highlightsAtom,
  selectedCommentIdAtom,
}) => {
  const highlights = useAtomValue(highlightsAtom);
  const disownedCommentIds = highlights
    .filter((highlight) => highlight.type === 'disowned')
    .map((highlight) => highlight.commentId);

  const selectedCommentId = useAtomValue(selectedCommentIdAtom);
  const isSelectedCommentDisowned = selectedCommentId
    ? disownedCommentIds.includes(selectedCommentId)
    : false;

  return (
    <DisownedCommentIndicator
      className={className}
      disownedCommentIds={disownedCommentIds}
      commentCount={highlights.length}
      onClickCommentIndicator={onClickCommentIndicator}
      extraSpacingForListItem={extraSpacingForListItem}
      isSelected={isSelectedCommentDisowned}
    >
      {children}
    </DisownedCommentIndicator>
  );
};
