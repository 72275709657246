import { TextBlock } from 'editor-content/Block.js';
import React, { ForwardedRef, useEffect, useMemo, useRef } from 'react';
import Linkable from 'editor-content/html/Linkable.js';
import { SelectionCommentsFeatureAtom } from '../publish/commentsSidebar/useComments/selectionComments/SelectionComments.js';
import { atom, useAtomValue, useSetAtom, useStore } from 'jotai';
import applyBlockHighlights from '../publish/selectionComments/applyBlockHighlights.js';
import { addSelectionHighlightIfNeeded } from '../publish/selectionComments/addSelectionHighlightIfNeeded.js';
import PublishedSelectionCommentMenu from '../publish/selectionComments/PublishedSelectionCommentMenu.js';
import mergeRefs from '../../../../junkDrawer/mergeRefs.js';
import useHandleHighlightClick from '../publish/selectionComments/useHandleHighlightClick.js';
import { useSelectedCommentHighlight } from '../publish/selectionComments/useSelectedCommentHighlight.js';
import { useRenderTextNodesAsHTMLString } from './useRenderTextNodesAsHTMLString.js';
import useOnBrowserSelectionChange from '../../../../editor/useContentEditable/useOnBrowserSelectionChange.js';
import BlockEditorAdapter from '../../editor/BlockEditorAdapter.js';
import { match, P } from 'ts-pattern';
import { DisownedCommentIndicatorFromAtom } from '../publish/selectionComments/DisownedCommentIndicator.js';
import Point from '../../../../domHelpers/Point.js';
import { contentSelectionToRange } from '../../../../editor/selection/contentSelection/ContentSelection.js';

export const withRenderTextNodesWithComments = <B extends TextBlock, P>(
  Wrapped: React.ComponentType<P>,
) =>
  React.forwardRef(function WithRenderTextNodesWithComments(
    {
      block,
      linkables,
      selectionCommentsFeatureAtom,
      className,
      ...otherProps
    }: P & {
      block: B;
      linkables: Linkable[];
      selectionCommentsFeatureAtom: SelectionCommentsFeatureAtom;
      className?: string;
    },
    forwardedRef: ForwardedRef<HTMLElement>,
  ) {
    const selectionCommentsFeature = useAtomValue(selectionCommentsFeatureAtom);

    const ref = useRef<HTMLElement>(null);

    const uiStateAtoms = useAtomValue(
      selectionCommentsFeature.blockSpecificUIAtomFamily(block.id),
    );

    // guarded to just contentSelection
    const selectionAtom = useMemo(() => {
      return atom((get) =>
        match(get(uiStateAtoms.selection))
          .with(
            {
              anchorOffset: P.number,
              focusOffset: P.number,
            },
            (selection) => selection,
          )
          .otherwise(() => null),
      );
    }, [uiStateAtoms.selection]);

    const selection = useAtomValue(selectionAtom);

    useOnBrowserSelectionChange(
      ref,
      useSetAtom(uiStateAtoms.changeSelection),
      BlockEditorAdapter.fromBaseAndExtent,
    );

    const shouldShowForm = useAtomValue(uiStateAtoms.shouldShowForm);

    const highlights = useAtomValue(
      selectionCommentsFeature.getBlockHighlights(block.id),
    );
    const textNodesWithPublishedCommentHighlights = applyBlockHighlights(
      block,
      addSelectionHighlightIfNeeded(highlights, shouldShowForm, selection),
    );
    useCanClickOnHighlightToSelectComment(selectionCommentsFeatureAtom, ref);

    const maybeSelectedCommentId = useAtomValue(
      selectionCommentsFeature.selectedCommentIdAtom,
    );
    useSelectedCommentHighlight(maybeSelectedCommentId ?? null);

    const selectFirstCommentForBlock = useSetAtom(
      selectionCommentsFeature.selectFirstCommentForBlockAtom,
    );

    const isBlockListItem = match(block.type)
      .with('bulleted-list-item', () => true)
      .with('numbered-list-item', () => true)
      .otherwise(() => false);

    useRenderTextNodesAsHTMLString(
      textNodesWithPublishedCommentHighlights,
      linkables,
      ref,
    );

    const store = useStore();
    const getMenuPosition = (childElement: HTMLElement): Point | null => {
      const selection = store.get(selectionAtom);
      const selectionContainerEl = ref.current;

      if (!(selectionContainerEl && selection)) {
        return null;
      }

      const domRange = contentSelectionToRange(selectionContainerEl, selection);

      const rect = domRange.getBoundingClientRect();
      const childElementRect = childElement.getBoundingClientRect();

      return [
        (rect.left + rect.right) / 2 - childElementRect.width / 2,
        rect.bottom + 16,
      ];
    };
    return (
      <>
        <DisownedCommentIndicatorFromAtom
          className={className}
          onClickCommentIndicator={() => selectFirstCommentForBlock(block.id)}
          selectedCommentIdAtom={selectionCommentsFeature.selectedCommentIdAtom}
          highlightsAtom={selectionCommentsFeature.getBlockHighlights(block.id)}
          extraSpacingForListItem={isBlockListItem}
        >
          <Wrapped
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            {...({ ...otherProps } as any)}
            ref={mergeRefs([ref, forwardedRef])}
            id={block.id}
          />
        </DisownedCommentIndicatorFromAtom>
        <PublishedSelectionCommentMenu
          selectionCommentsFeatureAtom={selectionCommentsFeatureAtom}
          publishedCommentSelectionUIStateAtom={selectionCommentsFeature.blockSpecificUIAtomFamily(
            block.id,
          )}
          getMenuPosition={getMenuPosition}
          block={block}
        />
      </>
    );
  });

function useCanClickOnHighlightToSelectComment(
  selectionCommentsFeatureAtom: SelectionCommentsFeatureAtom,
  ref: React.RefObject<HTMLElement>,
) {
  const handleHighlightClick = useHandleHighlightClick(
    selectionCommentsFeatureAtom,
  );
  useEffect(() => {
    const el = ref.current;
    el?.addEventListener('click', handleHighlightClick);

    return () => {
      el?.removeEventListener('click', handleHighlightClick);
    };
  }, [handleHighlightClick, ref]);
}
