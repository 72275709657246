import { Navigate } from 'react-router-dom';
import { sectionEditPath, zeckCoverEditPath } from '../../services/Paths.ts';
import withTouchRedirect from './withTouchRedirect.tsx';
import { useRequiredParams } from '../../routing/useRequiredParams.ts';
import useFetchZeck from './useFetchZeck.ts';
import useApi from '../../api/useApi.ts';
import getIdFromSlug from '../../getIdFromSlug.ts';
import { foldResult, pipe } from '../../result/Result.ts';

function EditZeckPage() {
  const { slug } = useRequiredParams('slug');

  const { fetchZeck } = useApi();

  const zeckResult = useFetchZeck(() => {
    return fetchZeck(getIdFromSlug(slug), false);
  }, [slug]);

  return pipe(
    zeckResult,
    foldResult({
      error: () => <Navigate to="/404" />,
      loading: () => null,
      success: (zeck) => {
        const firstSection = zeck.sections[0];

        if (!firstSection) {
          return <Navigate to={zeckCoverEditPath(zeck)} replace />;
        }

        return (
          <Navigate
            to={sectionEditPath(firstSection, zeck.companyId)}
            replace
          />
        );
      },
    }),
  );
}

export default withTouchRedirect(EditZeckPage);
