import React from 'react';
import { Table } from './Table.js';
import styles from './TableFromBlock.module.scss';
import { TableCell } from './TableCell.js';
import {
  TableBlock,
  TableBlockCellUncompressed,
  TableBlockMerge,
  tableCellUncompress,
} from 'editor-content/TableBlock.js';
import { getTableGridDimensions } from './getTableGridDimensions.js';
import { textToHtmlString } from 'editor-content/textNodesToHtmlString.js';
import { TableSelection } from './TableSelection.js';
import { highlightAttribute } from '../../../pages/zeck/highlights/highlights.js';

const TableWithFrozenRegions = React.forwardRef<
  HTMLTableElement,
  {
    tableBlock: TableBlock;
    setColumnRefAt?: (i: number) => (el: HTMLElement | null) => void;
    setRowRefAt?: (i: number) => (el: HTMLElement | null) => void;
    setCellRefAt?: (
      rowIndex: number,
      columnIndex: number,
    ) => (el: HTMLElement | null) => void;
    selection?: TableSelection | null;
    onSelectCell?: (selection: TableSelection) => void;
    highlights?: { commentId: string; selection: TableSelection }[];
    highlightSelectionId?: string | null;
    className?: string;
    drawGridlines?: boolean;
  }
>(function TableWithFrozenRegions(
  {
    className,
    drawGridlines,
    setColumnRefAt = () => () => {},
    setRowRefAt = () => () => {},
    setCellRefAt = () => () => {},
    selection = null,
    onSelectCell,
    highlights = [],
    highlightSelectionId,
    tableBlock,
  },
  forwardedRef,
) {
  const { numCols, frozenRowCount, frozenColumnCount } =
    getTableGridDimensions(tableBlock);

  const rowHeights = tableBlock.data.rowHeights;

  const frozenTableRows = tableBlock.data.rows.slice(0, frozenRowCount);
  const bodyTableRows = tableBlock.data.rows.slice(frozenRowCount);

  const tableColumns = Array.from({ length: numCols }, (_, i) => ({
    setEl: setColumnRefAt(i),
  }));

  const frozenTableColumns = tableColumns.slice(0, frozenColumnCount);
  const bodyTableColumns = tableColumns.slice(frozenColumnCount);

  return (
    <Table
      className={className}
      ref={forwardedRef}
      drawGridlines={drawGridlines}
    >
      {frozenTableColumns.length > 0 && (
        <colgroup className={styles.frozenColumnBorder}>
          {frozenTableColumns.map(({ setEl }, i) => {
            return <col key={i} ref={setEl} />;
          })}
        </colgroup>
      )}
      {bodyTableColumns.length > 0 && (
        <colgroup>
          {bodyTableColumns.map(({ setEl }, i) => {
            return <col key={i} ref={setEl} />;
          })}
        </colgroup>
      )}

      {frozenTableRows.length > 0 && (
        <thead className={styles.table__theadFrozen}>
          {frozenTableRows.map((row, rowIndex) => {
            const rowHeight = rowHeights?.[rowIndex];
            const style = rowHeight ? { height: rowHeight } : {};

            return (
              <tr key={rowIndex} ref={setRowRefAt(rowIndex)} style={style}>
                {row.cells.map((cell, columnIndex) => {
                  if (
                    isCellInOverlappingMerge(rowIndex, columnIndex, tableBlock)
                  )
                    return null;
                  const mergeOrigin = getCellMergeOrigin(
                    rowIndex,
                    columnIndex,
                    tableBlock,
                  );
                  const isSelected =
                    !!selection &&
                    selection.rowIndex === rowIndex &&
                    selection.columnIndex === columnIndex;

                  const isCommented = highlights.some(
                    (highlight) =>
                      highlight.selection.columnIndex === columnIndex &&
                      highlight.selection.rowIndex === rowIndex,
                  );

                  const isHighlighted = highlights.some(
                    (highlight) =>
                      highlight.selection.columnIndex === columnIndex &&
                      highlight.selection.rowIndex === rowIndex &&
                      highlight.commentId === highlightSelectionId,
                  );

                  const highlightIds = highlights
                    .filter(
                      (highlight) =>
                        highlight.selection.columnIndex === columnIndex &&
                        highlight.selection.rowIndex === rowIndex,
                    )
                    .map((highlight) => highlight.commentId);

                  const uncompressedCell = tableCellUncompress(cell);
                  const columnWidth =
                    tableBlock.data.columnWidths?.[columnIndex];

                  return (
                    <TableCell
                      ref={setCellRefAt(rowIndex, columnIndex)}
                      {...highlightAttribute(highlightIds)}
                      key={columnIndex}
                      format={uncompressedCell.format}
                      colSpan={mergeOrigin?.columnSpan}
                      rowSpan={mergeOrigin?.rowSpan}
                      width={columnWidth}
                      isSelected={isSelected}
                      isCommented={isCommented}
                      isHighlighted={isHighlighted}
                      selectionEnabled={!!onSelectCell}
                      onClick={() =>
                        onSelectCell && onSelectCell({ rowIndex, columnIndex })
                      }
                    >
                      <CellContent content={uncompressedCell.content} />
                    </TableCell>
                  );
                })}
              </tr>
            );
          })}
        </thead>
      )}
      <tbody>
        {bodyTableRows.map((row, arrayIndex) => {
          const rowIndex = arrayIndex + frozenRowCount;

          const rowHeight = rowHeights?.[rowIndex];
          const style = rowHeight ? { height: rowHeight } : {};

          return (
            <tr key={rowIndex} ref={setRowRefAt(rowIndex)} style={style}>
              {row.cells.map((cell, columnIndex) => {
                if (isCellInOverlappingMerge(rowIndex, columnIndex, tableBlock))
                  return null;
                const mergeOrigin = getCellMergeOrigin(
                  rowIndex,
                  columnIndex,
                  tableBlock,
                );

                const uncompressedCell = tableCellUncompress(cell);
                const columnWidth = tableBlock.data.columnWidths?.[columnIndex];

                const isSelected =
                  !!selection &&
                  selection.rowIndex === rowIndex &&
                  selection.columnIndex === columnIndex;

                const isCommented = highlights.some(
                  (highlight) =>
                    highlight.selection.columnIndex === columnIndex &&
                    highlight.selection.rowIndex === rowIndex,
                );
                const isHighlighted = highlights.some(
                  (highlight) =>
                    highlight.selection.columnIndex === columnIndex &&
                    highlight.selection.rowIndex === rowIndex &&
                    highlight.commentId === highlightSelectionId,
                );

                const highlightIds = highlights
                  .filter(
                    (highlight) =>
                      highlight.selection.columnIndex === columnIndex &&
                      highlight.selection.rowIndex === rowIndex,
                  )
                  .map((highlight) => highlight.commentId);

                return (
                  <TableCell
                    ref={setCellRefAt(rowIndex, columnIndex)}
                    key={columnIndex}
                    {...highlightAttribute(highlightIds)}
                    format={uncompressedCell.format}
                    colSpan={mergeOrigin?.columnSpan}
                    rowSpan={mergeOrigin?.rowSpan}
                    width={columnWidth}
                    isSelected={isSelected}
                    isCommented={isCommented}
                    isHighlighted={isHighlighted}
                    selectionEnabled={!!onSelectCell}
                    onClick={() =>
                      onSelectCell && onSelectCell({ rowIndex, columnIndex })
                    }
                  >
                    <CellContent content={uncompressedCell.content} />
                  </TableCell>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
});

const CellContent: React.FC<{
  content: TableBlockCellUncompressed['content'];
}> = ({ content }) => {
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: content.map(textToHtmlString).join(''),
      }}
    />
  );
};

function isCellInOverlappingMerge(
  rowIndex: number,
  columnIndex: number,
  tableBlock: TableBlock,
): boolean {
  const merges = tableBlock.data.merges;
  if (!merges) return false;

  return merges.some((merge) => {
    const isCurrentCellWithinMerge =
      columnIndex >= merge.startColumn &&
      columnIndex < merge.startColumn + merge.columnSpan &&
      rowIndex >= merge.startRow &&
      rowIndex < merge.startRow + merge.rowSpan;

    const isNotMergeOrigin = !(
      merge.startRow === rowIndex && merge.startColumn === columnIndex
    );

    return isCurrentCellWithinMerge && isNotMergeOrigin;
  });
}

function getCellMergeOrigin(
  rowIndex: number,
  columnIndex: number,
  tableBlock: TableBlock,
): undefined | TableBlockMerge {
  return tableBlock.data.merges?.find(
    (merge) => merge.startRow === rowIndex && merge.startColumn === columnIndex,
  );
}

export default TableWithFrozenRegions;
