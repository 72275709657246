import React from 'react';

import { CommentWithActions } from '../../useComments/useComments.ts';
import { isImageBlock, isTextBlock } from 'editor-content/Block.ts';
import QuotedImageBlock from './QuotedImageBlock.tsx';
import { match, P } from 'ts-pattern';
import { QuotedTextBlock } from './QuotedTextBlock.js';
import { QuotedTableBlock } from './QuotedTableBlock.js';

export const CommentQuote: React.FC<{
  comment: Pick<CommentWithActions, 'selection' | 'quoteStatus'>;
}> = ({ comment }) => {
  return match(comment.selection)
    .with(null, () => null)
    .with({ block: { type: 'table-cell' } }, ({ block }) => (
      <QuotedTableBlock block={block} quoteStatus={comment.quoteStatus} />
    ))
    .with({ block: P.when(isImageBlock) }, ({ block }) => (
      <QuotedImageBlock
        block={block}
        imageEdited={comment.quoteStatus === 'disowned'}
        imageDeleted={comment.quoteStatus === 'orphaned'}
      />
    ))
    .with({ block: P.when(isTextBlock) }, ({ block, snippetSelection }) => (
      <QuotedTextBlock
        block={block}
        snippetSelection={snippetSelection}
        quoteStatus={comment.quoteStatus}
      />
    ))
    .exhaustive();
};

export default CommentQuote;
